import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './Header';
import Footer from './Footer';

export default ({ children }) => (
    <>
        <Container fluid>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>
            <Row>
                <Col sm={{ span: 10, offset: 1 }}>
                    <p />
                    {children}
                    <p />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    </>
);