import React from 'react';
import StandardLayout from './StandardLayout';

const email = 'info@goldenbrown.io';
const Email = () => (<a href={`mailto:${email}`}>{email}</a>);

const site = 'imhere.today';
const Site = () => (<a href={`https://${site}`}>{site}</a>);

const PrivacyContent = () => (
    <>
        <h1>I’m Here Today Services Privacy Statement, effective as of November 2019</h1>

        <h2>Purpose</h2>
        <p>This Privacy Policy explains how we use any personal information that
        is collected about you, and your rights in relation to such information. We
        encourage you to read the following in its entirety prior to using our
        Services, or otherwise conducting business with us. Please note that this
        Privacy Policy is intended to apply to visitors to our website, and users/clients
        of I’m Here Today. Depending upon your relationship with I’m Here
        Today, some of the information below may not apply to you.</p>

        <h2>Policy</h2>
        <p>Golden Brown, LLC, operating the Services under the name I’m Here Today (“I’m
        Here Today”, “IHT”, “us”, “our”, or “we”) is committed to protecting the
        privacy of individuals who visit the <Site/> Site (“Visitors”), as well as
        the individuals, companies, and organizations associated with registered
        Visitors (“Customers”). Visitors, Customers, and users are collectively
        referred to in this Statement as “you” and “your” or as “User(s)”. This Privacy
        Policy (“Statement” or “Privacy Policy”) describes IHT’s privacy practices in
        relation to the use of IHT’s Site at <Site/>, and the software, data, and/or
        portals made available to Users in connection with the business services
        offered by IHT; this Privacy Policy also covers our collection and use of
        personal information required for the functioning of our services, including,
        but not limited to, scheduled event attendance information collection and
        documentation (collectively, the “Services”).</p>

        <h2>Scope</h2>
        <p>This Statement covers only data collected through our website or Services.</p>

        <p>For questions regarding this policy or to submit a privacy-related request, please
        email us at <Email/>.</p>

        <p>You acknowledge notice of this Statement and agree with and acknowledge the
        terms, conditions and responsibilities as set out below.</p>

        <p>We may make access available to third party sites through our Site. When
        accessing these third party sites, the privacy policy of the third-party
        applies, and this Statement does not apply.</p>

        <h2>What Information do we collect about you?</h2>

        <p>Our Site does not use cookies. However, our signin page has resources that
        are hosted by amazon.com and cookies may be used in this signin process 
        according to the terms and policies found at their site.
        The only information we collect is: (1) the
        information you actively submit to us through the Site, such as your email
        address with a request to contact you; (2) information you actively submit to
        us in the course of being a customer, such as contact or billing information;
        or (3) data you actively submit to complete your profile, respond to a roll
        call, or otherwise use the Services. We do not otherwise collect information
        about you, your device, browsing history, or other unrelated information. In
        addition to the foregoing, however, we may collect generalized information via
        a commercial analytics service about Site visits and activity on our Site.</p>

        <h2>How will we use the information about you?</h2>

        <p>We collect information about you to contact you, professionally maintain and
        manage our Site, process your order, manage your account, provide the Services,
        and to email you with relevant updates regarding the Services. (Please note
        that if you begin receiving emails from us and no longer wish to receive them,
        click UNSUBSCRIBE in any email we send to you, and you will be unsubscribed
        from our list immediately.) In processing your order or managing your account,
        we may send your details to, and also use information from, credit reference
        agencies, payment processing firms, banking service providers, fraud prevention
        agencies, and other third parties that are related to our business.</p>

        <p>When you submit information to us, it will only be used for the purposes of
        contacting you, or engaging in business with you as a client of our Services.
        Aggregated and anonymized data may also be used for analytics and research
        purposes regarding classroom, event, or workplace attendance.</p>

        <h2>Marketing</h2>

        <p>We have no intention of sending unnecessary marketing information. You have a
        right, at any time, to stop us from contacting you for marketing purposes or
        giving your information to other third parties. If you do receive marketing
        communication and no longer wish to be contacted for marketing purposes, please
        contact us or click UNSUBSCRIBE the next time you receive an email from us.</p>

        <h2>Types of Data and Collection Methods</h2>

        <p>We receive data that you actively submit through the Site or Services (“Data”)
        as described below.</p>

        <p>When you request information or otherwise actively send us Data through the IHT
        public Site, the paid client Site, or through a third party host such as AWS
        (“actively submitted data”), we may collect Data such as, but not limited to,
        name, e-mail address(es), telephone number(s), or user profile information. You
        can opt-out of providing information by not entering it when asked, and, if
        such information is required in order to allow us to respond to your inquiry,
        you may not receive any response from us at all if we have no viable way to
        contact you, or you will receive a notice advising you of this situation and
        the nature of our request for additional information. When you submit Data via
        the Services, you will know what Data we collect through the Services, because
        you actively submit it.  If you decline to submit certain Data through the
        Services, or submit incorrect Data through the Services you are hereby advised
        that the services may not operate as intended.  For example, if you refuse to
        enter your name in your profile, or there is an error in your profile, your
        attendance may not be accurately recorded.</p>

        <p>Additionally, when you visit the IHT public Web site(s), although no commercial
            analytics are currently used, AWS Pinpoint Analytics could be used to
        passively track information on your computer and Internet connection, such as
        the IP address of your computer and/or Internet service provider, the date and
        time you access the Web site(s), the Internet address of Web sites from which
        you link to our Services, the computer technology you are using and your
        movements and preferences on our Web site(s).  Analytics and other
        services, however, are third parties responsible for their own privacy policies
        and measures. </p>

        <h2>Collection Purposes and Use of Data</h2>

        <p>We collect Data via our Services primarily for purposes of administering,
        providing, protecting, and improving our Services, to better understand the
        preferences and behaviors of our Users, to identify server problems, to compile
        aggregated statistics about site usage, to compile aggregated statistics about
        attendance, and to help personalize your experience with our Services. We do
        not deliver third party online advertisements on our sites but we may advertise
        our products and services on others’ websites. Nevertheless, we may promote our
        Customers or the existence of a business relationship between IHT and any other
        organization or individual on our Site or within our Services.      </p>

        <p>IHT may also use Data you voluntarily provide to perform the services you
        request. For example, if you fill out a “Contact Me” form, we will use the
        information provided to contact you about your interest in the Services.</p>

        <h2>Web Site Navigational Information: Cookies, Web Beacons, and Analytics</h2>

        <p>IHT does not use commonly-used information-gathering tools, such as cookies and
        Web beacons, to collect information as you navigate the Site (“Web Site
        Navigational Information”).</p>

        <h2>Signing In: Cookies, Web Beacons, and Analytics</h2>

        <p>As mentioned above, IHT does also utilize services from Amazon for signing in.
        That signin process allows users to sign in to IHT directly, or through Google-
        connected sign in services, or Facebook-connected signin services. If and when an
        IHT user selects to sign in via Google, Facebook, or any other third party service,
        the IHT user is responsible for understanding and abiding by the terms of those sites,
        inlcuding their use of beacons, cookies, and other analytics, that may or may not be
        known by IHT. IHT is not responsible for any failures, breaches, or other damages or
        injuries caused by any third party service.</p>

        <h2>Payments: Cookies, Web Beacons, and Analytics</h2>

        <p>When providing payment for IHT services, certain information is required to
        verify your identity and process your payment information. This information may
        be submitted to IHT and/or a third party payment partner such as Paypal. In the
        case of any information, we request the minimum information necessary to properly
        process your payment. However, payment service providers such as Paypal may use
        cookies, beacons, or other forms of analytics to process your payment(s) that may
        or may not be known by IHT. IHT is not responsible for any failures, breaches,
        or other damages or injuries caused by any third party payment service.></p>

        <h2>Links to Other Web Sites</h2>

        <p>IHT occasionally provides links to other entities related to the Services for
        your convenience in locating certain information, Services, and/or products.
        Please be advised that when you access these other sites, you are leaving the
        IHT Site and/or Service(s) and that these third party sites are maintained by
        organizations over which IHT has no control.</p>

        <p>Accordingly, a link (or any other information) provided by IHT or its
        representatives does not constitute endorsement of the content, viewpoint,
        policies, legality, products, or services provided or advertised on the third
        party site. Once you navigate to a site not maintained by IHT, you are subject to
        the terms and conditions of that site and all inquiries regarding such third
        party sites or services should be directed to the owner of such site or
        services, not IHT.</p>

        <h2>Social Media</h2>

        <p>If you post material to a blog or other social media feed located on or
        accessed through the Services, or post anything accessed or visible through the
        Services on social media, you should be aware that any personally identifiable
        information you submit can be read, collected, or used by other users of this
        Site or our Services, and could be used to send you unsolicited messages.
        Likewise, if you share information, such as roll call codes, through social
        media, text message, sms, board post, ftp site, or through any other social
        channels, you may be removed from using the Services, and may be responsible
        for consequences external to IHT.  We are not responsible for the personally
        identifiable information you choose to submit via social media or any other
        publicly-available forum, online or not, and any consequences thereby.</p>

        <h2>Opt-Out</h2>

        <p>You always have the right to opt-out. To submit a request, please 
        contact <Email/>.</p>

        <p>In connection with promotions or other projects, we may ask you specifically
        whether you have objections against a certain kind of Data use or sharing. If
        you opt-out under such circumstances, we will respect your decision. To opt out
        of receiving marketing communications from IHT via email please click on the
        “opt-out” link in the email communication or submit a request to the email
        address you see throughout this notice. Please be advised that communications
        sent by IHT via other means such as USPS mail are initiated via lists
        maintained by IHT from explicit opt-in scenarios such as website downloads or
        tradeshows. These lists provide for an opt-out option at the time you submit
        your information. Please note that our affiliates and other data recipients
        have their own data privacy policies, which may differ from ours and you would
        have to contact them separately with respect to opt-out requests. If you are
        having difficulty opting out of any lists, communications, promotions, or from
        any Services, please contact us at <Email/> for further assistance.</p>

        <p>Please note that it is not possible to opt out of any Services that do not
        personally identify you.</p>

        <h2>Correcting and Updating Your Information</h2>

        <p>IHT’s Users may electronically submit Data or information, including payment
        information, that is used for hosting and/or processing purposes (“Customer
        Data”). IHT is not responsible for Customer Data data submitted to and/or
        maintained by third party sites such as, but not limited to, AWS or PayPal. IHT
        will not host or retain payment information or any personal data collected by us or a
        third party seller for the purpose of accessing, purchasing, using, or
        maintaining the Services, except to the extent that information such as, but
        not limited to, payment information, may need to be retained for some limited
        time per legal requirements or practical requirements such as issuing a refund.
        Customer Data may include Customer contact information (such as email address,
        name, phone number), financial information (such as credit card information),
        or other requested information.  IHT will not review, share, distribute, or
        reference any such Customer Data except as provided in this policy, through
        another agreement, or as may be required by law. In accordance with the Terms
        of Service and this Privacy Policy, IHT may access Customer Data only for the
        purpose of providing the Services, or preventing or addressing service or
        technical problems, or as may be required by law. If you wish to correct or
        update your Data, please contact us at <Email/>.</p>

        <h2>Your California Privacy Rights</h2>

        <p>California law provides that California residents have a right to request
        businesses to tell them how their personal information has been shared, if at
        all, with third parties for the third parties’ direct marketing purposes. While
        IHT does not collect or share or personal information for any purpose other
        than what we describe here, you are still welcome to contact us at <Email/>
        to submit a formal request and receive a formal response.</p>

        <h2>Your Texas Privacy Rights</h2>

        <p>The Texas Identity Theft Enforcement and Protection Act does not technically
        apply to IHT because we do not collect - unless you actively submit it to us -
        your name, address, social security number, credit card number, or other
        similar information. Nonetheless, we believe in following the spirit of this
        law, protecting the identity of people, and notifying people impacted by any
        type of data breach. For more information and your rights in Texas, you may
        visit:
        https://www.texasattorneygeneral.gov/cpd/protecting-consumers-personaldata</p>

        <h2>Privacy Rights in other States &amp; Countries</h2>

        <p>If you live in one of the 48 States that isn’t Texas or California, or a
        foreign country, you may also have unique privacy or data rights. If you wish
        to contact us pursuant to another state or foreign law, please contact us at
        <Email/> and be sure to provide a citation or link to any law you
        reference so we can do our best to help you.  We will do our best to respond to
        any such inquiries within thirty (30) days.</p>

        <h2>General Data Protection Regulation (GDPR)</h2>

        <p>IHT has a legitimate commercial interest in processing data for the purpose of
        providing valuable professional services to our clients, namely data regarding
        attendance at academic, professional, or other events. To achieve this Service
        for our clients, we collect and process minimal data submitted from mobile
        devices and personal computing devices, and we utilize measures to ensure that
        the data we do collect, cannot be associated with any particular human being
        beyond the type of association intended by the Services, and explained
        throughout this Privacy Policy. We recognize that your privacy is important,
        and to help protect your interests, IHT is guided by incorporating privacy
        protections wherever possible into our data collection and data processing
        practices.</p>

        <p>If you are a European citizen or otherwise entitled to the protection of
        European laws, you may have additional rights under the GDPR. In honor of those
        rights, IHT furnishes the following information:</p>

        <p>You have the right to be informed of what data we may have, how we process it
        for business purposes, and how we store it. Reading this Privacy Policy
        carefully will provide all such information, but if you are having trouble
        finding it yourself within this document, you may contact us at <Email/>.
        You also have the right to contact us and inquire if we
        have any data about you, and how any such data is used. We will do our best to
        respond to any such inquiries in a timely manner. If you make an inquiry,
        please understand that it is very well possible that we will have no data
        associated with you because our data collection and processing methods seek to
        minimize the data we collect, and much of the data we collect can not readily
        be associated with a particular human being, or is not retained and/or
        deleted.</p>

        <p>You also have the right to correct any data we do possess about you. Who wants
        incorrect data? Not us, and not you either! Please email us with any
        rectification requests at <Email/>. In the event that any of your
        information is incorrect, you may also request that we discontinue processing
        of your data.</p>

        <p>You also have the right to contact us and request that we simply erase any data
        associated with you. (Please note that if you are a paying client of IHT, such
        a request will require conversation, as certain information such as how we can
        contact you or payment records information can not practicably be deleted while
        we are doing business together.)</p>


        <p>You also have the right to object to our use of your data. If you wish to
        submit an objection, you must provide an explanation for your objection so we
        can determine how to best service your request. Please send objections and
        explanations to <Email/>.</p>

        <p>IHT does not intend for its software or services to be used for any automated
        decisionmaking purposes.</p>

        <h2>Do Not Track</h2>

        <p>Certain web browsers and other devices you may use to access the Services may
        permit you to indicate your preference that you do not wish to be “tracked”
        online. At this time, the Services do not respond to “Do Not Track” signals
        submitted to third parties such as web browsing services.</p>

        <h2>Security &amp; Transfers</h2>

        <p>Transmissions over the Internet are never 100% secure or error-free. However,
        we take reasonable steps to protect your Data and our Services from loss,
        misuse, and unauthorized access, disclosure, alteration, and destruction.  You,
        as a User, are encouraged to be mindful of taking practical privacy measures
        while using the Services, such as those measures commonly used in public spaces
        to protect your personal privacy.</p>

        <h2>Children Under 13</h2>

        <p>IHT is committed to protecting the privacy needs of children, and we encourage
        parents and guardians to take an active role in their children’s online
        activities and interests. Neither the IHT public website nor are our Services
        independently directed to children under 13 years old, and we do not market to
        or knowingly collect any personally identifiable information from a child under
        the age of 13 without the consent of the child’s parent or legal guardian. If a
        parent or guardian becomes aware that his or her child has provided us with
        personal or contact information without their consent, the parent or guardian
        should contact us immediately 
        at <Email/>. Otherwise, in the event
        that any school district or other organization using IHT services is likely to
        be working with children under the age of 13, such district, organization, or
        institution is responsible for ensuring that IHT Services are used properly.
        If we become aware that we have inadvertently collected personally identifiable
        information from a child under the age of 13 outside the scope of our stated
        Services and/or approvals given to IHT by any organization, we will
        immediately take reasonable steps to delete such information from our Site and/or
        Services, and take all other remedial measures necessary to protect the
        interests of a child under the age of 13.</p>

        <h2>Amendments</h2>

        <p>We reserve the right to change, modify, add or remove portions of this
        Statement from time to time and in our sole discretion, but will alert you that
        changes have been made by indicating on the Statement the date it was last
        updated. When you visit the Services, you are accepting the current version of
        this Statement as posted on the Service at that time. Especially due to the
        fluid nature of data security compliance, we recommend that users revisit this
        Statement on occasion to learn of any changes.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions about this Statement, please email us 
        at <Email/>.</p>

        <p><small>© 2019 Golden Brown, LLC<br />Privacy Policy</small></p>
    </>
);

export default () => (
    <>
        <StandardLayout>
            <PrivacyContent />
        </StandardLayout>
    </>
)