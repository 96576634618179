import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Table, ButtonToolbar } from 'react-bootstrap';

import DateUtils from '../util/DateUtils';

import FunctionCard from 'common/FunctionCard';
import TimeZoneNote from 'common/TimeZoneNote';

export default class extends React.Component {

    static propTypes = {
        rollcall: PropTypes.object.isRequired,
        downloadEnabled: PropTypes.bool.isRequired,
        active: PropTypes.bool,
        frequency: PropTypes.number,
        error: PropTypes.string,
    };

    buildCSVData(responses, rollcallName, rawScore) {

        const headers = [
            'Last name',
            'First name',
            'EID',
            'Special codes',
            'Raw score',
            'Percent',
        ];

        const rows = responses.map(r => [
            r.responder.familyName,
            r.responder.givenName,
            r.responder.institutionId,
            rollcallName,
            rawScore,
            '100',
        ]);

        return [headers, ...rows];
    }

    /* Example responses:
        [{
            responseId: 'b5f076f6-bd4a-4e27-9b60-44164779374d',
            respondedUtc: '2019-08-13T03:40:20.091Z',
            responder:
            {
                givenName: 'Russell',
                familyName: 'Brown',
                email: 'zatnicatel@murgatroid.com',
                institutionId: '1234567'
            }
        }]
    */
    render() {

        const { rollcall: rc, frequency: freq, active } = this.props;
        const rs = rc.responses;

        let frequencyMessage;
        if (active) {
            frequencyMessage = freq >= 0
                ? <span>{`Updates every ${DateUtils.durationInSecondsToWords(this.props.frequency)}`}</span>
                // eslint-disable-next-line
                : <span><a href="javascript:window.location.reload(true)">Refresh to update responses</a></span>;
        }

        return (
            <FunctionCard heading="Responses" error={this.props.error} headingNote={frequencyMessage}>
                <ButtonToolbar>
                    {this.props.downloadEnabled && <CSVLink className="btn btn-outline-primary mr-2" data={this.buildCSVData(rs, rc.rollcallName, rc.rawScore)} filename="al.csv" target="_self">Download CSV</CSVLink>}
                </ButtonToolbar>
                <br />
                {rs && rs.length > 0
                    ? <Table striped hover>
                        <thead>
                            <tr>
                                <th>Family Name</th>
                                <th>Given Name</th>
                                <th>Responded <TimeZoneNote intro='time zone' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rs.map((r, i) => (
                                <tr key={i}>
                                    <td>{r.responder.familyName}</td>
                                    <td>{r.responder.givenName}</td>
                                    <td>{DateUtils.formatISOString(r.respondedUtc)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    : <p>No responses{active && ' yet'}</p>}
            </FunctionCard>
        );
    }
}