import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Container, Row, Col, Card } from 'react-bootstrap';

import Responses from './response/MyResponsesContoller';
import StandardLayout from './StandardLayout';
import { AuthFilter, ProfileCompleteFilter, EmailVerifiedFilter } from './auth/AuthFilters';
import RespondToRollcall from './response/RespondToRollcallController';
import { ActiveSubscriptionFilter, InactiveSubscriptionFilter } from './subscription/SubscriptionFilters';
import Checkout from './checkout/CheckoutController';

// const insp = (obj) => (inspect(obj, null, 5));

const reload = () => document.location.reload();
const noop = () => { };

const AuthHomePage = (props) => {

    // A bit of a hack to force rerendering of the responses
    const [key, setKey] = useState(Math.random());
    const onSuccess = () => { setKey(Math.random()) };

    return (
        <AuthFilter>
            <ProfileCompleteFilter onMismatch={() => (<Redirect to="/profile" />)}>
                <EmailVerifiedFilter onMismatch={() => (<Redirect to="/confirmemail" />)}>
                    <StandardLayout>
                        <ActiveSubscriptionFilter>
                            <RespondToRollcall onSuccess={onSuccess} />
                        </ActiveSubscriptionFilter>
                        <InactiveSubscriptionFilter>
                            <Card style={{ padding: "1.5rem" }}>
                                <Card.Title>Please purchase a $10 signup to respond to rollcalls</Card.Title>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col md={{ span: 6, offset: 3 }}>
                                                <Checkout
                                                    onSuccess={reload}
                                                    onFailure={noop}
                                                    onCancel={noop}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                        </InactiveSubscriptionFilter>
                        <br />
                        <ActiveSubscriptionFilter>
                            <Responses key={key} />
                        </ActiveSubscriptionFilter>
                    </StandardLayout>
                </EmailVerifiedFilter>
            </ProfileCompleteFilter>
        </AuthFilter>
    );
};

export default withRouter(AuthHomePage);