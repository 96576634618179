import React from 'react';
import PropTypes from 'prop-types';
import { Connect } from 'aws-amplify-react';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AuthContext from '../auth/AuthContext';
import CreateRollcallView from './CreateRollcallView';

const log = new Logger('CreateRollcallController', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

export default class extends React.Component {

    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: null,
        };
    }
    
    createRollcallGql = `
        mutation newRollcall($rollcallName: String!, $rawScore: Float = 100.0) {
            openRollcall(name: $rollcallName, rawScore: $rawScore) {
                rollcallName
                rollcallId
                codeword
                openedUtc
                closedUtc
                active
            }
        }
    `;

    render() {
        
        return (
            <AuthContext.Consumer>
                { auth => (
                    <Connect mutation={{
                        query: this.createRollcallGql,
                        variables: {},
                    }}>
                        {({ mutation }) => (
                            <CreateRollcallView
                                createRollcall={async (input) => {
                                    log.debug(`CREATE_ROLLCALL_MUTATION_INPUT(input=${insp(input)})`);
                                    try {
                                        this.setState({ loading: true, errors: null });
                                        const result = await mutation({ ...input });
                                        this.setState({ loading: false });

                                        // result looks like: 
                                        // { openRollcall: 
                                        //     { rollcallName: 'agf',
                                        //       rollcallId: '04c72291-047d-4ae8-9a87-a829a131903d',
                                        //       codeword: 'JTT',
                                        //       openedUtc: '2019-07-17T02:00:31.392Z',
                                        //       closedUtc: null,
                                        //       active: true } } })

                                        log.debug(`CREATE_ROLLCALL_MUTATION_SUCCESS(result=${insp(result)})`);

                                        this.props.onSuccess(result.data.openRollcall.rollcallId);
                                    }
                                    catch (e) {
                                        log.error(`CREATE_ROLLCALL_MUTATION_ERROR(error=${insp(e)})`);
                                        this.setState({ loading: false, errors: e.errors.map(e => e.message)});
                                    }
                                }}
                                loading={this.state.loading}
                                controllerErrors={this.state.errors}
                                onCancel={this.props.onCancel}
                            />
                        )}
                    </Connect>
                )}
            </AuthContext.Consumer>
        );
    }
}
