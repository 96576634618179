import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export default class extends React.Component {

    static propTypes = {
        closeRollcall: PropTypes.func.isRequired,
    };

    render() {
        return (
            <>
                <Button style={{ margin: '0 auto', display: 'block' }} onClick={this.props.closeRollcall}>Close Rollcall</Button>
            </>
        );
    }
}