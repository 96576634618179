import { Logger, API } from 'aws-amplify';
import inspect from 'browser-util-inspect';

const log = new Logger('RecordPaymentService', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

/*
    type Mutation {
        ...
        recordPayment(processorTransactionId: String!): Payment
    }

    type Payment {
        id: ID!
        processorTransactionId: String!
        processorPayerId: String!
        processorJson: String
        transactionAmount: String!
        transactionType: String!
        transactionDate: AWSDateTime!
        parentTransaction: String
    }
*/

const recordPaymentGql = `
    mutation recordPayment($processorTransactionId: String!) {
        recordPayment(processorTransactionId: $processorTransactionId) {
            id
            processorTransactionId
            processorPayerId
            processorJson
            transactionAmount
            transactionType
            transactionDate
            parentTransaction
        }
    }
`;

export default async function recordPayment(orderId) {

    try {
        log.debug(`RECORD_PAYMENT_SERVICE(orderId=${orderId})`);
        const payment = await API.graphql({ query: recordPaymentGql, variables: { processorTransactionId: orderId } });
        log.debug(`RECORD_PAYMENT_SUCCESS(payment=${insp(payment)})`);
        return payment;
    }
    catch (e) {
        /* Example error looks like:
        {
            data: null,
            errors:
                [{
                    path: null,
                    locations: [{ line: 2, column: 3, sourceName: null }],
                    message: 'Validation error of type MissingFieldArgument: Missing field argument processorTransactionId @ \'recordPayment\''
                },
                {
                    path: null,
                    locations: [{ line: 2, column: 17, sourceName: null }],
                    message: 'Validation error of type UnknownArgument: Unknown field argument orderId @ \'recordPayment\''
                },
                {
                    path: null,
                    locations: [{ line: 5, column: 5, sourceName: null }],
                    message: 'Validation error of type FieldUndefined: Field \'processoryPayerId\' in type \'Payment\' is undefined @ \'recordPayment/processoryPayerId\''
                }]
        }
        */
        log.error(`RECORD_PAYMENT_ERROR(error=${insp(e)})`);
        throw e;
    }
}