import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';

import { Formik, Form, Field, ErrorMessage } from 'formik';

const log = new Logger('ConfirmEmailView', 'DEBUG');

export default class extends React.Component {
    
    static propTypes = {
        submitCode: PropTypes.func.isRequired,
        resendCode: PropTypes.func.isRequired,
        error: PropTypes.string,
    };
    
    constructor(props) {
        super(props);
        
        this.state = { isSendingCode: false, codeHasBeenResent: false };
    }
    
    onSubmit = async ({ code }, { setSubmitting }) => { 
        log.info(`CONFIRM_EMAIL_SUBMIT_CODE(code=${code})`);
        await this.props.submitCode(code);
        log.info(`CONFIRM_EMAIL_SUBMIT_CODE_SUCCESS()`);
        setSubmitting(false);
    };
    
    resendCode = async () => {
        log.info(`CONFIRM_EMAIL_RESEND_CODE()`);
        this.setState({ isSendingCode: true, codeHasBeenResent: false });
        await this.props.resendCode();
        this.setState({ isSendingCode: false, codeHasBeenResent: true });
        log.info(`CONFIRM_EMAIL_RESEND_CODE_SUCCESS()`);
    }
    
    validateCode = (code) => /^[0-9]{6}$/.test(code) ? null : "Please enter a six-digit number";
    
    render() {
        return (
            <>
                <h1>Confirm Email</h1>
                <Formik initialValues = {{ code: '' }}
                    onSubmit={this.onSubmit}>
                    {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        { this.state.codeHasBeenResent && <p>A new code has been sent to you.</p>}
                        { this.props.error && <p>{this.props.error}</p>}
                        <p>Validation code:</p>
                        <Field type="text" name="code" validate={this.validateCode}/>
                        <ErrorMessage name="code" component="div"/>
                        <button 
                            onClick={() => { setFieldValue('code', ''); this.resendCode(); }}
                            disabled={isSubmitting || this.state.isSendingCode}
                        >
                            Resend code
                        </button>
                        <button type="submit" disabled={isSubmitting || this.state.isSendingCode}>Submit</button>
                    </Form>
                    )}
                </Formik>
            </>
        );
    }
}