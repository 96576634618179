import React from 'react';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';

import ConfigContext from '../config/ConfigContext';
import AmplifyContext from './AmplifyContext';

class AmplifyProvider extends React.Component {

    static propTypes = {
        config: PropTypes.shape({
            amplify: PropTypes.object.isRequired,
        }).isRequired,
    };

    constructor(props) {

        super(props);

        this.state = {
            awsAmplify: null,
        };
    }

    componentDidMount() {

        if (!this.props.config === null)
            throw Error('config prop is null');

        const config = { ...this.props.config };

        if (!config.amplify)
            throw Error('config does not contain an amplify element');

        this.setOauthRedirects(config.amplify);
        this.setCustomHeader(config.amplify);

        Amplify.configure(config.amplify);

        this.setState({ awsAmplify: Amplify });
    }

    setOauthRedirects(amplify) {

        // OAuth redirects from Cognito's hosted UI should return to whatever origin the site is running. Ideally, I'd do this
        // setting in AuthProvider, but Amplify pollutes the logs if you configure it twice.

        /* global URL */
        amplify.Auth.oauth.redirectSignIn = amplify.Auth.oauth.redirectSignOut = new URL(window.location).origin;

        console.debug(`DYNAMIC_CONFIG(amplify.Auth.oauth.redirectSignIn=${amplify.Auth.oauth.redirectSignIn})`);
    }

    setCustomHeader(amplify) {

        // This is necessary for the AppSync resolver to receive user attributes. By default (and with no option to override),
        // Amplify passes the accessToken, which contains no user data other than sub.

        // TODO: Figure out how to deal with token expiration, per https://auth0.com/docs/api-auth/why-use-access-tokens-to-secure-apis

        amplify.API = {
            graphql_headers: async () => {
                try {
                    const token = (await Amplify.Auth.currentSession()).idToken.jwtToken;
                    return { Authorization: token }
                }
                catch (e) {
                    console.error(e);
                    return {};
                }
            }
        };
    }

    render() {

        return (
            <AmplifyContext.Provider value={this.state}>
                {this.props.children}
            </AmplifyContext.Provider>
        );
    }
}

export default React.forwardRef((props, ref) => (
    <ConfigContext.Consumer>
        {config => (<AmplifyProvider {...props} config={config} ref={ref} />)}
    </ConfigContext.Consumer>
));
