const findEnvironment = (configs, site) => {
    const environments = configs.environments;
    // console.log(`environments = ${inspect(environments)}`);
    for (const environment of environments) {
        // console.log(`environment = ${inspect(environment)}`)
        for (const sitePattern of environment.sites) {
            const siteRegExp = new RegExp(sitePattern);
            if (siteRegExp.test(site)) {
                console.info(`Configuration for ${environment.environment} selected`);
                return environment.environment;
            } else {
                // console.log(`site ${site} does not match sitePattern ${sitePattern}`);
            }
        }
    }
    
    return null;
};
    
const findConfig = (configs, environment) => {
    return configs.configs[environment];
};
    
export default (configs, site) => {
    const environment = findEnvironment(configs, site);
    const config = findConfig(configs, environment);
    return config;
}
    