import React from 'react';
import StandardLayout from './StandardLayout';

export default () => (
    <>
        <StandardLayout>
        <h1>Under Construction</h1>
        <img
            src="https://media.giphy.com/media/WoErBV0Detcum33FJQ/giphy.gif"
            alt="Dancing construction worker"
        />
        </StandardLayout>
    </>
)