import React from 'react';
import PropTypes from 'prop-types';
import { Auth, Hub, Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AmplifyContext from '../amplify/AmplifyContext';
import AuthContext from './AuthContext';
import AuthState from './AuthState';
import UserStateService from './UserStateService';

const log = new Logger('AuthProvider', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

class AuthProvider extends React.Component {
    
    static propTypes = {
        awsAmplify: PropTypes.object.isRequired,
    };

    constructor(props) {

        super(props);

        this.userStateService = new UserStateService(Auth);
        
        const unauthenticatedState =  { ...UserStateService.unauthenticatedState };
        unauthenticatedState.authState = AuthState.LOADING;

        this.state = {
            ...unauthenticatedState,
            error: null,
            loading: true,
            updateAttributes: this.updateAttributes,
            forceRefresh: this.forceRefresh,
            confirmEmail: this.confirmEmail,
            resendCode: this.resendCode,
            changePassword: this.changePassword,
            oauthSignInLink: null,
        };

        Hub.listen('auth', this.onHubCapsule);
    }

    /**
     * Use this to tell AuthProvider that backend state may have changed
     */
    forceRefresh = async () => {
        await this.onHubCapsule({ channel: 'auth', payload: { event: 'signIn' } });
    };

    setUserState(userState) {
        const oldState = this.state;
        log.debug(`AUTH_TRANSITION(from=${insp(oldState)}, to=${insp(userState) || 'null'})`);

        this.setState(userState);
    }

    updateAttributes = async (newAttributes) => {
        
        log.debug(`UPDATE_ATTRIBUTES(newAttributes=${insp(newAttributes)})`);

        this.setState({ error: null, loading: true });
        
        try {
            this.setState({ loading: true });
            await this.userStateService.updateAttributes(newAttributes);
            this.setState({ loading: false });
        }
        catch (e) {
            log.error(`UPDATE_USER_ATTRIBUTES_FAILED(${insp(e)})`);
            this.setState({ error: e.message, loading: false });
            throw e;
        }
        
        await this.forceRefresh();
    }
    
    confirmEmail = async (code) => {
        
        this.setState({ error: null, loading: true });
        
        try {
            await this.userStateService.confirmEmail(code);
            await this.forceRefresh();
            this.setState({ loading: false });
        }
        catch(e) {
            this.setState({ error: e.message, loading: false });
            throw e;
        }
    }

    resendCode = async () => {
        
        this.setState({ error: null, loading: true });
        
        try {
            await this.userStateService.resendCode();
            this.setState({ loading: false });
            await this.forceRefresh();
        }
        catch (e) {
            this.setState({ error: e.message, loading: false });
            throw e;
        }
    }
    
    changePassword = async (oldPassword, newPassword) => {
        
        this.setState({ error: null, loading: true });
        
        try {
            await this.userStateService.changePassword(oldPassword, newPassword);
            // Don't need to force refresh; password isn't part of state
            this.setState({ loading: false });
        }
        catch (e) {
            this.setState({ error: e.message, loading: false });
            throw e;
        }
    }
    
    // Determine the hosted URL for signing in (same URL that withOAuth uses--and this code is cloned from there)
    determineOAuthSignInLink() {

        const { oauth={}, userPoolWebClientId } = Auth.configure();

        const { 
            domain,  
            redirectSignIn,
            responseType
        } = oauth;

        const url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;
        // window.location.assign(url);  
        return url;
    }

    onHubCapsule = async (capsule) => {

        const { channel, payload, source } = capsule;
        log.debug(`HUB_EVENT(channel=${insp(channel)}, payload.event=${insp(payload.event)}, source=${source})`);

        if (channel === 'auth') {
            switch (payload.event) {

                case 'signIn':
                    this.setState({ loading: true });
                    const userState = await this.userStateService.getUserData({ allowCache: false });
                    this.setUserState({ ...userState, loading: false });
                    break;

                case 'signIn_failure':
                    log.error(`SIGNIN_FAILURE(capsule=${insp(capsule)}`);
                    this.setUserState(this.userStateService.unauthenticatedState);
                    break;

                default:
                    log.debug(`IGNORED_AUTH_EVENT(payload.event=${payload.event}, source=${source})`)
                    break;
            }
        }
    };
    
    async componentDidMount() {
        
        // Fake a hub event to force signin attempt and get initial state
        await this.forceRefresh();

        if (this.props.awsAmplify) {
            const oauthSignInLink = this.determineOAuthSignInLink();
            this.setState({ oauthSignInLink });
        }
    }
    
    render() {
        window.authState = this.state; // TODO: Debug only
        log.debug(`RENDERING_AUTH_PROVIDER(state=${insp(this.state)})`);
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default React.forwardRef((props, ref) => (
    <AmplifyContext.Consumer>
        { awsAmplify => ( <AuthProvider {...props} awsAmplify={awsAmplify} ref={ref} /> ) }
    </AmplifyContext.Consumer>
));
