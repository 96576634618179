import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import inspect from 'browser-util-inspect';

import RespondToRollcallView from './RespondToRollcallView';

const insp = obj => inspect(obj, null, 5);
const log = new Logger('RespondToRollcallController', 'DEBUG');

export default class RespondToRollcallController extends React.Component {

    static propTypes = {
        onSuccess: PropTypes.func,
    };

    constructor(props) {

        super(props);

        this.state = { loading: false, rollcall: null, errors: null }
    }

    respondToRollcallGql = `
        mutation respondToRollcall($codeword: String!) {
            respondToRollcall(codeword: $codeword) {
                responseId
                respondedUtc
                rollcall {
                    rollcallId
                    rollcallName
                    openedUtc
                    active
                }
                caller {
                    sub
                    givenName
                    familyName
                    email
                    institutionId
                }
            }
        }
    `;

    respondToRollcall = mutation => async input => {

        log.debug(`RESPOND_TO_ROLLCALL(input=${insp(input)})`);

        try {
            /* Example successful response looks like:
            {
                data:
                {
                    respondToRollcall:
                    {
                        responseId: '4aeac557-c390-455d-b823-b24584445dd6',
                        respondedUtc: '2019-08-11T16:44:16.886Z',
                        rollcall:
                        {
                            rollcallId: '3a1be906-12dd-4e73-97e4-3f597bcc3123',
                            rollcallName: 'aug11.2',
                            openedUtc: '2019-08-11T16:43:36.306Z',
                            active: false
                        },
                        caller:
                        {
                            sub: 'e2b5bec5-7b32-499a-a665-2b383e7545b4',
                            givenName: 'Ima',
                            familyName: 'Responder',
                            email: 'responder20190628.1@ignorethis.org',
                            institutionId: null
                        }
                    }
                }
            }
            */

            this.setState({ loading: true, rollcall: null, errors: null });
            const result = await mutation({ ...input });

            log.debug(`RESPOND_TO_ROLLCALL_SUCCESS(result=${insp(result)})`);

            const rollcall = result.data.respondToRollcall.rollcall;
            this.setState({ loading: false, rollcall, errors: null });

            this.props.onSuccess && this.props.onSuccess();
        }
        catch (e) {
            /* Example duplicate response error looks like:
            {
                data: null,
                errors:
                    [{
                        path: ['respondToRollcall'],
                        data: null,
                        errorType: 'Lambda:Unhandled',
                        errorInfo: null,
                        locations: [{ line: 2, column: 3, sourceName: null }],
                        message: 'DUPLICATE_RESPONSE(responderSub=e2b5bec5-7b32-499a-a665-2b383e7545b4, rollcallId=8f0c2a49-47b5-43b3-9c2a-03c8c9592742)'
                    }]
            };
            */

            /* Example rollcall not found or rollcall closed error looks like:
            {
                data: null,
                errors: [{
                    path: ['respondToRollcall'],
                    data: null,
                    errorType: 'Lambda:Unhandled',
                    errorInfo: null,
                    locations: [{ line: 2, column: 3, sourceName: null }],
                    message: 'ROLLCALL_NO_ACTIVE_FOUND(codeword=JTT)'
                }]
            }
            */

            log.error(`RESPOND_TO_ROLLCALL_MUTATION_ERROR(${insp(e)})`);

            const errorMessages = e.errors.map(({ message }) => {
                switch (true) {
                    case /^DUPLICATE_RESPONSE/.test(message):
                        return 'You have already responded to this rollcall';
                    case /^ROLLCALL_NO_ACTIVE_FOUND/.test(message):
                        return 'There is no active rollcall with that codeword';
                    default:
                        return message;
                }
            });

            this.setState({ loading: false, rollcall: null, errors: errorMessages });
        }
    };

    render() {

        return (
            <Connect mutation={{ query: this.respondToRollcallGql, variables: {} }}>
                {({ mutation }) => (
                    <>
                        <RespondToRollcallView
                            respondToRollcall={this.respondToRollcall(mutation)}
                            loading={this.state.loading}
                            rollcall={this.state.rollcall}
                            controllerErrors={this.state.errors}
                        />
                    </>
                )}
            </Connect>
        );
    }
}