import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import ConfigContext from '../config/ConfigContext';
import PayPalCheckout from './PayPalCheckout';

import recordPayment from './RecordPaymentService';

const log = new Logger('CheckoutController', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

const CheckoutController = ({ config, onSuccess, onFailure, onCancel }) => {

    const { paypal: { clientId }, product: { price: amount, currency } } = config;

    console.log(`CHECKOUT_CONFIG(data=${JSON.stringify({ clientId, onSuccess, onFailure, onCancel, amount, currency })})`);

    const onCapture = async (details) => {
        log.debug(`ON_CAPTURE(details=${insp(details)})`);
        try {
            const payment = await recordPayment(details.id);
            log.debug(`ON_CAPTURE_SUCCESS(payment=${payment})`);
            onSuccess();
        }
        catch (e) {
            log.error(`ON_CAPTURE_FAILURE(error=${e})`);
            // Can't rethrow, as it goes back into PayPal's code
        }
    };

    return (
        <PayPalCheckout {...{ clientId, onCapture, onFailure, onCancel, amount, currency }} />
    );
};

CheckoutController.propTypes = {
    config: PropTypes.shape({
        paypal: PropTypes.shape({
            clientId: PropTypes.string.isRequired,
        }).isRequired,
        product: PropTypes.shape({
            price: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,
        }),
    }).isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default (props) => (
    <ConfigContext.Consumer>
        {config => (<CheckoutController {...props} config={config} />)}
    </ConfigContext.Consumer>
);