import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';
import TimeZoneNote from 'common/TimeZoneNote';
import DateUtils from '../util/DateUtils';

export default class extends React.Component {

    static propTypes = {
        rollcall: PropTypes.object.isRequired,
        lateResponsesEnabled: PropTypes.bool,
        reopenRollcall: PropTypes.func,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }

    /* Example rollcall:
        {
            rollcallId: '926ffba3-de47-4099-b962-a839a5de149f',
            rollcallName: 'aug11.7',
            codeword: 'RZG',
            rawScore: 96,
            openedUtc: '2019-08-12T00:14:09.536Z',
            closedUtc: '2019-08-13T04:04:02.974Z',
            active: false,
            caller:
            {
                givenName: 'Ima',
                familyName: 'Responder',
                email: 'responder20190628.1@gymkata.org',
                institutionId: '543218'
            },
            responses:
                [{
                    responseId: 'b5f076f6-bd4a-4e27-9b60-44164779374d',
                    respondedUtc: '2019-08-13T03:40:20.091Z',
                    responder:
                    {
                        givenName: 'Russell',
                        familyName: 'Brown',
                        email: 'zatnicatel@murgatroid.com',
                        institutionId: '1234567'
                    }
                },
                {
                    responseId: '2c387b1f-5da1-4f86-a6d4-a82d34abe701',
                    respondedUtc: '2019-08-13T04:00:10.127Z',
                    responder:
                    {
                        givenName: 'Russ',
                        familyName: 'Brown',
                        email: 'murgatroid@zatnicatel.com',
                        institutionId: '12345'
                    }
                }]
        }
    */

    // When reopening a rollcall that is already open, the return payload from AppSync looks like:
    // {
    //     "data": null,
    //     "errors": [
    //       {
    //         "path": [
    //           "reopenRollcall"
    //         ],
    //         "data": null,
    //         "errorType": "Lambda:Unhandled",
    //         "errorInfo": null,
    //         "locations": [
    //           {
    //             "line": 216,
    //             "column": 3,
    //             "sourceName": null
    //           }
    //         ],
    //         "message": "The conditional request failed"
    //       }
    //     ]
    //   }


    render() {

        const { rollcallName, codeword, rawScore, openedUtc, closedUtc } = this.props.rollcall;

        return (
            <FunctionCard heading="Rollcall" error={this.props.error}>
                <ButtonToolbar>
                    {this.props.lateResponsesEnabled && <Button variant="outline-success" onClick={this.props.reopenRollcall}>Invite more responses</Button>}
                </ButtonToolbar>
                <br/>
                <Table striped hover>
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <td>{rollcallName}</td>
                        </tr>
                        <tr>
                            <th>Codeword</th>
                            <td>{codeword}</td>
                        </tr>
                        <tr>
                            <th>Raw Score</th>
                            <td>{rawScore}</td>
                        </tr>
                        <tr>
                            <th>Opened</th>
                            <td>{DateUtils.formatISOString(openedUtc)} <TimeZoneNote /></td>
                        </tr>
                        <tr>
                            <th>Closed</th>
                            <td>{DateUtils.formatISOString(closedUtc)} <TimeZoneNote /></td>
                        </tr>
                    </tbody>
                </Table>
            </FunctionCard>
        );
    }
}