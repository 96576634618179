import { parseISO, format, differenceInSeconds } from 'date-fns';
// import differenceInSeconds from 'date-fns/differenceInSeconds';

export default class {

    static formatISOString(str) {

        return str ? format(this.parseISOString(str), `MMM dd, yyyy - hh:mm a`) : '';
    }

    static parseISOString(str) {

        return parseISO(str);
    }
    
    static elapsedTimeInSecondsSinceISOString(str) {
        
        const then = this.parseISOString(str);
        const now = new Date();
        const elapsed = differenceInSeconds(now, then);

        return elapsed;
    }
    
    static durationInSecondsToWords(secs) {
        
        const days = Math.floor(secs / 86400);
        const hours = Math.floor(secs / 3600 % 24);
        const minutes = Math.floor(secs / 60 % 60);
        const seconds = Math.floor(secs % 60);

        let message = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        message = message.replace(/(?:0. )+/, '');

        return message;
    }

    static elapsedTimeSinceTextForISOString(str) {

        const elapsed = this.elapsedTimeInSecondsSinceISOString(str);
        return this.durationInSecondsToWords(elapsed);
    }

    static getTimeZoneName() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
}
