import React from 'react';
import PropTypes from 'prop-types';
import { Logger, API } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import ConfigContext from '../config/ConfigContext';

const insp = (obj) => inspect(obj, null, 5);
const log = new Logger('SubscriptionFilters', 'DEBUG');

export class GenericSubscriptionFilter extends React.Component {

    static propTypes = {
        condition: PropTypes.func.isRequired,
        onMismatch: PropTypes.func,
        name: PropTypes.string,
    };

    state = { loading: true, id: null, sub: null, active: null, startDate: null, endDate: null };

    /*
        type Subscription {
            id: ID!
            sub: String!
            active: Boolean!
            startDate: AWSDateTime
            endDate: AWSDateTime
        }
    */
    getUserSubscriptionGql = `
        query rollcallWithResponses {
            getMy {
                subscription {
                    id
                    sub
                    active
                    firstActive
                    endedOn
                }
            }
        }
    `;

    async fetchData() {

        try {
            log.debug(`FETCH_SUBSCRIPTION()`);
            const result = await API.graphql({ query: this.getUserSubscriptionGql, variables: {} });
            log.debug(`FETCH_SUBSCRIPTION_SUCCESS(result=${insp(result)})`);
            return result;
        }
        catch (e) {
            log.debug(`FETCH_SUBSCRIPTION_ERROR(error=${insp(e)})`);
            throw e;
        }
    }

    async componentDidMount() {

        this.setState({ loading: true });
        const data = await this.fetchData();
        const { data: { getMy: { subscription } } } = data;
        this.setState({ loading: false, ...subscription });
        log.debug(`SUBSCRIPTION_FETCHED(state=${insp(this.state)})`);
    }

    render() {

        if (this.state.loading) {
            return (<p>Loading...</p>);
        }

        if (this.props.condition(this.state, this.props.config))
            return this.props.children;

        if (this.props.onMismatch) {
            log.debug(`SUBSCRIPTION_FILTER_MISMATCH(name=${this.props.name}, state=${insp(this.state)})`);
            return this.props.onMismatch();
        }

        return (<></>);
    }
}

// Filter that just returns its children if the price of the product is zero or less; otherwise, it checks for a paid subscription.
function ConfigAwareGenericSubscriptionFilter({ name, condition, onMismatch, children }) {

    return (
        <ConfigContext.Consumer>
            {config => (
                <GenericSubscriptionFilter name={name} condition={condition} config={config} onMismatch={onMismatch}>
                    {children}
                </GenericSubscriptionFilter>
            )}
        </ConfigContext.Consumer>
    );
}

const subscriptionCostsSomething = config => parseInt(config.product.price) > 0;

export function ActiveSubscriptionFilter(props) {

    const condition = ({ active }, config) => (!!active || !subscriptionCostsSomething(config));

    return (
        <ConfigAwareGenericSubscriptionFilter name="ActiveSubscriptionFilter" condition={condition} onMismatch={props.onMismatch}>
            {props.children}
        </ConfigAwareGenericSubscriptionFilter>
    );
}

export function InactiveSubscriptionFilter(props) {

    const condition = ({ active }, config) => (!active && subscriptionCostsSomething(config));

    return (
        <ConfigAwareGenericSubscriptionFilter name="InactiveSubscriptionFilter" condition={condition} onMismatch={props.onMismatch}>
            {props.children}
        </ConfigAwareGenericSubscriptionFilter>
    );
}

ActiveSubscriptionFilter.propTypes = InactiveSubscriptionFilter.propTypes = {
    onMismatch: PropTypes.func,
}