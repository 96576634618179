import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RootPage from './RootPage';
import ProfilePage from './auth/ProfilePage';
import ConfirmEmailPage from './auth/ConfirmEmailPage';
import ChangePasswordPage from './auth/ChangePasswordPage';
import SigninPage from './auth/SigninPage';
import SignoutPage from './auth/SignoutPage';
import CreateRollcallPage from './rollcall/CreateRollcallPage';
import RollcallPage from './rollcall/RollcallPage';
import RollcallsPage from './rollcall/RollcallsPage';
import CheckoutPage from './checkout/CheckoutPage';
import AboutPage from './AboutPage';
import PrivacyPage from './PrivacyPage';

const Routes = props => (
    <Router>
        <Switch>
            <Route path="/" exact component={RootPage} />
            <Route path="/profile"
                render={({ history }) => (
                    <ProfilePage
                        onSuccess={() => history.replace("/")}
                        onCancel={() => history.replace("/")}
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/confirmemail"
                render={({ history }) => (
                    <ConfirmEmailPage
                        onSuccess={() => { console.log(`Routes.ConfirmEmail.onSuccess()`); history.replace("/"); }}
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/changepassword"
                render={({ history }) => (
                    <ChangePasswordPage
                        onSuccess={() => { console.log(`Routes.ChangePassword.onSuccess()`); history.replace("/"); }}
                        onCancel={() => { console.log(`Routes.ChangePassword.onCancel()`); history.replace("/"); }}
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/signin"
                render={({ history }) => (
                    <SigninPage
                        onSuccess={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/signout"
                render={({ history }) => (
                    <SignoutPage
                        onSuccess={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/createrollcall"
                render={({ history }) => (
                    <CreateRollcallPage
                        onSuccess={(rollcallId) => history.replace(`/rollcall/${rollcallId}`)}
                        onCancel={() => history.replace("/")}
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/rollcall/:rollcallId"
                render={({ history }) => (
                    <RollcallPage
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/rollcalls"
                render={({ history }) => (
                    <RollcallsPage
                        onMismatch={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/checkout"
                render={({ history }) => (
                    <CheckoutPage
                        onMismatch={() => history.replace("/")}
                        onSuccess={() => history.replace("/")}
                        onCancel={() => history.replace("/")}
                        onFailure={() => history.replace("/")}
                    />
                )}
            />
            <Route path="/about"
                render={() => (<AboutPage />)}
            />
            <Route path="/privacy"
                render={() => (<PrivacyPage />)}
            />
        </Switch>
    </Router>
);

export default Routes;