import React from 'react';
import PropTypes from 'prop-types';
import inspect from 'browser-util-inspect';
import { Logger } from 'aws-amplify';
import { Formik, Form as FormikForm, Field } from 'formik';
import { Form as BSForm, Button, ButtonToolbar } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';

import validationSchema from './ProfileValidationSchema';

const log = new Logger('ProfileView', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

export default class extends React.Component {

    static propTypes = {
        email: PropTypes.string,
        familyName: PropTypes.string,
        givenName: PropTypes.string,
        institutionId: PropTypes.string,

        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func, // Omit to disable cancel (e.g., when directed here by profile incompleteness)
        loading: PropTypes.bool.isRequired,
        controllerErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        allowEmailChange: PropTypes.bool,
    };

    async submit(attributes, setSubmitting) {
        console.log(`Starting submit; attributes=${attributes}`);
        await this.props.onSubmit(attributes);
        setSubmitting(false);
        console.log('Done with submit');
    }

    // TODO: This can probably be replaced by Formik's `dirty`
    extractModifiedAttributes(newAttributes) {

        const modifiedAttributes = {};
        for (const newAttribute in newAttributes) {
            // When form first renders, some aren't set yet
            if (!newAttributes[newAttribute]) continue;

            const newAttributeValue = newAttributes[newAttribute].trim();

            if (newAttributes.hasOwnProperty(newAttribute) && newAttributeValue !== this.props[newAttribute])
                modifiedAttributes[newAttribute] = newAttributeValue;
        }

        return modifiedAttributes;
    }

    userHasChangedAFormField(formValues) {
        return Object.getOwnPropertyNames(this.extractModifiedAttributes(formValues)).length > 0;
    }

    render() {
        log.debug(`RENDERING_PROFILE_VIEW(props=${insp(this.props)})`);
        return (
            <FunctionCard heading="Update Profile" error={this.props.controllerErrors}>
                <Formik
                    initialValues={{
                        email: this.props.email || '',
                        givenName: this.props.givenName || '',
                        familyName: this.props.familyName || '',
                        institutionId: this.props.institutionId || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => this.submit(validationSchema.cast(values), setSubmitting)}
                >
                    {({ isSubmitting, isValid, values, touched, errors }) => (
                        <BSForm as={FormikForm}>
                            <BSForm.Group controlId="email">
                                <BSForm.Label>Email address</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="email"
                                    minLength="1"
                                    maxLength="256"
                                    disabled={!this.props.allowEmailChange}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                <BSForm.Control.Feedback type="invalid" name="email">
                                    {errors.email}
                                </BSForm.Control.Feedback>
                            </BSForm.Group>
                            <BSForm.Group controlId="givenName">
                                <BSForm.Label>Given name</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="givenName"
                                    maxLength="256"
                                    isInvalid={touched.givenName && !!errors.givenName}
                                />
                                <BSForm.Control.Feedback type="invalid" name="givenName">
                                    {errors.givenName}
                                </BSForm.Control.Feedback>
                                <BSForm.Text>(usually the first name in Western cultures)</BSForm.Text>
                            </BSForm.Group>
                            <BSForm.Group controlId="familyName">
                                <BSForm.Label>Family name</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="familyName"
                                    minLength="1"
                                    maxLength="256"
                                    isInvalid={touched.familyName && !!errors.familyName}
                                />
                                <BSForm.Control.Feedback type="invalid" name="familyName">
                                    {errors.familyName}
                                </BSForm.Control.Feedback>
                                <BSForm.Text>(usually the last name in Western cultures)</BSForm.Text>
                            </BSForm.Group>
                            <BSForm.Group controlId="institutionId">
                                <BSForm.Label>Institution ID</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="institutionId"
                                    maxLength="256"
                                    isInvalid={touched.institutionId && !!errors.institutionId}
                                />
                                <BSForm.Control.Feedback type="invalid" name="institutionId">
                                    {errors.institutionId}
                                </BSForm.Control.Feedback>
                            </BSForm.Group>
                            <ButtonToolbar>
                                <Button variant="primary" className="mr-2" type="submit" disabled={isSubmitting || !isValid || !this.userHasChangedAFormField(values)}>Submit</Button>
                                {this.props.onCancel && (<Button variant="secondary" disabled={isSubmitting} onClick={this.props.onCancel}>Cancel</Button>)}
                            </ButtonToolbar>
                        </BSForm>
                    )}
                </Formik>
            </FunctionCard>
        );
    }
}