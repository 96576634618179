import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AuthContext from './AuthContext';
import ChangePasswordView from './ChangePasswordView';

const log = new Logger('ChangePasswordController', 'DEBUG');
const insp = obj => inspect(obj, null, 2);

class ChangePasswordController extends React.Component {
    
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        changePassword: PropTypes.func.isRequired,
        error: PropTypes.string,
    }
    
    changePassword = async (oldPassword, newPassword) => {
        
        try {
            log.debug(`CHANGE_PASSWORD()`);
            await this.props.changePassword(oldPassword, newPassword);
            log.debug(`CHANGE_PASSWORD_SUCCESSFUL()`);
            this.props.onSuccess();
        }
        catch (e) {
            log.error(`CHANGE_PASSWORD_FAILED(${insp(e)})`);
        }
    }
    
    render() {
        return (
            <ChangePasswordView
                onSubmit={this.changePassword}
                onCancel={this.props.onCancel}
                error={this.props.error}
            />
        );
    }
}

export default React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
        { ({ changePassword, error }) => (
            <ChangePasswordController
                {...props}
                ref={ref}
                changePassword={changePassword}
                error={error}
            />
        )}
    </AuthContext.Consumer>
));