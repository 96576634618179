import React from 'react';
import { withOAuth } from 'aws-amplify-react';

import { UnauthFilter } from './AuthFilters';

class SigninPage extends React.Component {

    componentDidMount() {
        this.props.OAuthSignIn();
    }

    render() {
        return (<div>Signing in...</div>);
    }
}

// I split the auth filter into a separate component to prevent SigninPage from mounting and then redirecting

const GuardedSigninPage = props => (<UnauthFilter onMismatch={props.onMismatch}><SigninPage {...props}/></UnauthFilter>);

export default withOAuth(GuardedSigninPage);