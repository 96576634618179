import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AuthState from './AuthState';
import IdentityProvider from './IdentityProvider';
import { mapFromCognitoAttributes, mapToCognitoAttributes } from './CognitoUserAttributeMapper';
import profileValidationSchema from './ProfileValidationSchema';

const log = new Logger('UserStateService', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

const EMAIL_ATTRIBUTE = 'email';
        
export default class UserStateService {

    static unauthenticatedState = {
        authState: AuthState.SIGNIN,
        attributes: null,
        identityProvider: IdentityProvider.NONE,
        profileComplete: false,
        emailVerified: false,
    };

    constructor(amplifyAuth) {
        this.amplifyAuth = amplifyAuth;
    }

    async getCurrentAuthenticatedUser({ allowCache = true }) {

        try {
            const user = await this.amplifyAuth.currentAuthenticatedUser({ bypassCache: !allowCache });
            return user;
        }
        catch (e) {
            // Not actually an error; this is just Amplify's whiny way of telling us there's no logged-in user.
            log.debug(`CURRENT_AUTHENTICATED_USER_ERROR()`, e);
            return null;
        }
    }

    async getUserData({ allowCache = true }) {
        
        log.debug(`GET_USER_DATA(allowCache=${allowCache})`);

        const user = await this.getCurrentAuthenticatedUser({ allowCache: allowCache });
        
        log.debug(`USER_RETRIEVED(user=${insp(user)})`);
        
        if (!user) {
            return UserStateService.unauthenticatedState;
        }

        const authState = AuthState.SIGNEDIN;
        const attributes = mapFromCognitoAttributes(user.attributes);
        const identityProvider = this.determineIdentityProvider(attributes);
        const profileComplete = await this.determineProfileComplete(attributes);
        const emailVerified = this.determineEmailVerified(attributes);

        return {
            authState,
            attributes,
            identityProvider,
            profileComplete,
            emailVerified,
        };
    }

    determineIdentityProvider(attributes) {

        if (!attributes) throw new Error(`NO_USER_ATTRIBUTES()`);

        // When user signs up with user pool with email/password instead of federated authentication, Cognito doesn't set the identities 
        // attribute at all.

        if (!attributes.identities) {
            log.debug('IDP_DETERMINED(idp=cognito)');
            return IdentityProvider.COGNITO;
        }

        const identitiesJson = attributes.identities;
        const identities = JSON.parse(identitiesJson);
        const providerType = identities[0].providerType;

        console.dir(identities);

        let identityProvider;
        switch (providerType) {
            case 'Facebook':
                log.debug('IDP_DETERMINED(idp=Facebook)');
                identityProvider = IdentityProvider.FACEBOOK;
                break;
            case 'Google':
                log.debug('IDP_DETERMINED(idp=Google)');
                identityProvider = IdentityProvider.GOOGLE;
                break;
            default:
                throw new Error(`UNKNOWN_IDENTITY_PROVIDER_TYPE(providerType=${providerType})`);
        }

        return identityProvider;
    }

    async determineProfileComplete(attributes) {
        log.debug(`IS_PROFILE_COMPLETE(attributes=${insp(attributes)})`);
        const profileComplete = await profileValidationSchema.isValid(attributes);
        log.debug(`IS_PROFILE_COMPLETE(complete=${profileComplete})`);
        return profileComplete;
    }

    determineEmailVerified(attributes) {

        let emailVerified;
        const identityProvider = this.determineIdentityProvider(attributes);
        switch (identityProvider) {

            // Federated authentication providers do their own verification
            case IdentityProvider.FACEBOOK:
            case IdentityProvider.GOOGLE:
                emailVerified = true;
                break;

            case IdentityProvider.COGNITO:
                emailVerified = attributes.emailVerified;
                break;

            default:
                throw new Error(`UNEXPECTED_IDENTITY_PROVIDER(provider=${identityProvider})`);
        }

        log.debug(`IS_EMAIL_VERIFIED(emailVerified=${emailVerified})`);
        return emailVerified;
    }

    async updateAttributes(newAttributes) {

        const user = await this.getCurrentAuthenticatedUser({ allowCache: false });

        if (!user || !user.attributes) {
            throw Error(`UPDATE_USER_ATTRIBUTES_NOT_SIGNED_IN()`);
        }

        const existingAttributes = mapFromCognitoAttributes(user.attributes);

        const modifiedAttributes = {};
        for (const newAttribute in newAttributes) {
            const newAttributeValue = newAttributes[newAttribute].trim();
            if (newAttributes.hasOwnProperty(newAttribute) && newAttributeValue !== existingAttributes[newAttribute])
                modifiedAttributes[newAttribute] = newAttributeValue;
        }

        if (Object.getOwnPropertyNames(modifiedAttributes).length === 0) {
            log.debug(`NO_USER_ATTRIBUTES_MODIFIED()`);
            return;
        }

        const cognitoAttributesToUpdate = mapToCognitoAttributes(modifiedAttributes);

        log.info(`USER_ATTRIBUTES_TO_MODIFY(${insp(cognitoAttributesToUpdate)})`);

        await this.amplifyAuth.updateUserAttributes(user, cognitoAttributesToUpdate);

        log.info(`USER_ATTRIBUTES_MODIFIED()`);
    }
    
    async confirmEmail(code) {
        
        try {
            log.debug(`USER_CONFIRM_CODE(attribute=${EMAIL_ATTRIBUTE}, code=${code})`);
            await this.amplifyAuth.verifyCurrentUserAttributeSubmit(EMAIL_ATTRIBUTE, code);
            // Force user attributes to be refreshed
            log.debug(`USER_CONFIRM_CODE_SUCCESSFUL()`);
        }
        catch (e) {
            log.debug(`USER_CONFIRM_CODE_FAILED(error=${insp(e)})`);
            throw e;
        }
    }
    
    async resendCode() {
        
        try {
            log.debug(`USER_RESEND_CODE()`);
            await this.amplifyAuth.verifyCurrentUserAttribute(EMAIL_ATTRIBUTE);
            log.debug(`USER_RESEND_CODE_SUCCESSFUL()`);
        }
        catch (e) {
            log.debug(`USER_RESEND_CODE_FAILED(error=${insp(e)})`);
            throw e;
        }
    }
    
    async changePassword(oldPassword, newPassword) {
        
        const user = await this.getCurrentAuthenticatedUser({ allowCache: true });
        
        try {
            log.debug(`USER_CHANGE_PASSWORD()`);
            await this.amplifyAuth.changePassword(user, oldPassword, newPassword);
            log.debug(`USER_CHANGE_PASSWORD_SUCCESSFUL()`);
        }
        catch (e) {
            log.debug(`USER_CHANGE_PASSWORD_FAILED(error=${insp(e)}`);
            throw e;
        }
    }
    
}
