import React from 'react';
import PropTypes from 'prop-types';

import { AuthFilter } from '../auth/AuthFilters';
import StandardLayout from '../StandardLayout';
import Checkout from './CheckoutController';

function CheckoutPage({ onMismatch, onSuccess, onCancel, onFailure }) {
    return (
        <AuthFilter onMismatch={onMismatch}>
            <StandardLayout>
                <Checkout { ...{ onSuccess, onCancel, onFailure }}/>
            </StandardLayout>
            </AuthFilter>

    )
}

CheckoutPage.propTypes = {
    onMismatch: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default CheckoutPage;