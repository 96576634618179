import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const fontSize = "70%";
const textStyle = { fontSize };

export default () => (
    <footer>
        <Navbar bg="info" variant="dark">
            <Navbar.Text style={textStyle} className="text-light mr-auto">
                ⓒ Copyright 2019, Golden Brown LLC
            </Navbar.Text>
            <Navbar.Text style={textStyle} className="mx-3">
                <LinkContainer to="/privacy">
                    <Nav.Link active className="text-light">Privacy</Nav.Link>
                </LinkContainer>
            </Navbar.Text>
            <Navbar.Text style={textStyle} className="">
                <LinkContainer to="/about">
                    <Nav.Link active className="text-light">About</Nav.Link>
                </LinkContainer>
            </Navbar.Text>
        </Navbar>
        <p className="invisible">{process.env.REACT_APP_VERSION}</p>
    </footer>
);