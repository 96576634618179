import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import AuthContext from './auth/AuthContext';
import { AuthFilter, UnauthFilter, CognitoIdpFilter } from './auth/AuthFilters';

import safeGet from './util/SafeGet';

function Header(props) {
    return (
        <header>
            <Navbar bg="info" variant="dark" expand="md">
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <h1>
                            <img
                                src="/imhere-icon-spiky-black.png"
                                style={{
                                    width: "30px",
                                    height: "65px",
                                    margin: "1rem",
                                }}
                                alt="stylized figure with raised hand"
                            />
                            I'm Here Today</h1>
                    </Navbar.Brand>
                </LinkContainer>
                <AuthFilter>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav className="mr-sm-2">
                            <NavDropdown active title="Rollcalls">
                                <LinkContainer to="/createrollcall">
                                    <NavDropdown.Item>Create a rollcall</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/rollcalls">
                                    <NavDropdown.Item>My rollcalls</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            <NavDropdown active title={`${props.familyName}, ${props.givenName}`}>
                                <LinkContainer to="/profile">
                                    <NavDropdown.Item>Profile</NavDropdown.Item>
                                </LinkContainer>
                                <CognitoIdpFilter>
                                    <LinkContainer to="/changepassword">
                                        <NavDropdown.Item>Change password</NavDropdown.Item>
                                    </LinkContainer>
                                </CognitoIdpFilter>
                                <LinkContainer to="/signout">
                                    <NavDropdown.Item>Sign out</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </AuthFilter>
                <UnauthFilter>
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className="mr-sm-2">
                        <Nav.Item>
                            <LinkContainer to="/signin">
                                <Nav.Link active>Sign in</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </UnauthFilter>
            </Navbar>
        </header>
    );
}

export default () => (
    <AuthContext.Consumer>
        {(auth) => {
            const givenName = safeGet(['attributes', 'givenName'], auth),
                familyName = safeGet(['attributes', 'familyName'], auth);
            return (<Header givenName={givenName} familyName={familyName} />);
        }}
    </AuthContext.Consumer>
);