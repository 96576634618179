import React from 'react';
// import { Table, UncontrolledAlert } from 'reactstrap';
// import FontAwesome from 'react-fontawesome';
import { Table } from 'react-bootstrap';

import inspect from 'browser-util-inspect';

import DateUtils from '../util/DateUtils';
import FunctionCard from 'common/FunctionCard';
import TimeZoneNote from 'common/TimeZoneNote';

const insp = obj => inspect(obj, null, 5);

export default class extends React.Component {

    render() {

        console.info(insp(this.props));

        return (
            <FunctionCard heading="Recent Responses" error={this.props.errors}>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Codeword</th>
                            <th>Date responded <TimeZoneNote intro="time zone"/></th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    { this.props.responses.map((rsp, index) => (
                        <tr key={index}>
                            <td>{rsp.rollcall.codeword}</td>
                            <td>{DateUtils.formatISOString(rsp.respondedUtc)}</td>
                            <td>{rsp.rollcall.rollcallName}</td>
                        </tr>
                     ))}
                    </tbody>
                </Table>
            </FunctionCard>
        );
    }
}