import React from 'react';
import { Card } from 'react-bootstrap';

import { UnauthFilter } from './auth/AuthFilters';
import AuthContext from './auth/AuthContext';
import StandardLayout from './StandardLayout';

const UnauthHomePage = (props) => (
    <UnauthFilter>
        <StandardLayout>
            <Card style={{ padding: "1.5rem" }}>
                <Card.Title>Welcome to I'm Here Today!</Card.Title>
                <Card.Body>
                    Please <a href={props.oauthSignInLink}>sign in.</a>
                </Card.Body>
            </Card>
            
        </StandardLayout>
    </UnauthFilter>
);

// export default withOAuth(UnauthHomePage);

export default React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
        { auth => (
            <UnauthHomePage
                {...props}
                ref={ref}
                {...auth}
            />
        )}
    </AuthContext.Consumer>
));