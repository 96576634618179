import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';

// import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Button, ButtonToolbar } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';

const log = new Logger('ChangePasswordView', 'DEBUG');

function ChangePasswordView(props) {

    const {
        onCancel,
        error,          // This is for errors coming from the controller
        values,
        errors,         // This is for errors coming from Formik validation
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        touched,
    } = props;

    return (
        <FunctionCard heading="Change Password" error={error}>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="oldPassword">
                    <Form.Label>Old password</Form.Label>
                    <Form.Control type="text" name="oldPassword" onChange={handleChange} onBlur={handleBlur} value={values.oldPassword} isInvalid={touched.oldPassword && errors.oldPassword} />
                    <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="newPassword">
                    <Form.Label>New password</Form.Label>
                    <Form.Control type="text" name="newPassword" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} isInvalid={touched.newPassword && errors.newPassword} />
                    <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                </Form.Group>
                <ButtonToolbar>
                    <Button variant="primary" className="mr-2" type="submit" disabled={!isValid || isSubmitting}>Submit</Button>
                    <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                </ButtonToolbar>
            </Form>
        </FunctionCard>
    );
}

ChangePasswordView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    error: PropTypes.string,
};

const validationSchema = yup.object()
    .shape({
        oldPassword: yup.string()
            .min(8, 'Please enter a password of at least 8 characters')
            .required('A password is required')
            .max(256, 'Password must be no longer than 256 characters'),
        newPassword: yup.string()
            .min(8, 'Please enter a password of at least 8 characters')
            .required('A password is required')
            .max(256, 'Password must be no longer than 256 characters'),
    });

const handleSubmit = async ({ oldPassword, newPassword }, { setSubmitting, props: { onSubmit } }) => {
    log.info(`CHANGE_PASSWORD()`);
    await onSubmit(oldPassword, newPassword);
    log.info(`CHANGE_PASSWORD_SUCCESS()`);
    setSubmitting(false);
};

export default withFormik({
    mapPropsToValues: props => ({ oldPassword: '', newPassword: '' }),
    handleSubmit,
    displayName: 'ChangePasswordViewWithFormik',
    validationSchema,
})(ChangePasswordView);