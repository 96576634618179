import React from 'react';
import { Alert } from 'react-bootstrap';

export default function ErrorToast({ header, message }) {
    return !!message ? (
        <Alert variant="danger">
            <Alert.Heading>
                {header}
            </Alert.Heading>
            {message}
        </Alert>
    ) : null;
}