import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
// import inspect from 'browser-util-inspect';

import AuthContext from './AuthContext';
import AuthState from './AuthState';
import IdentityProvider from './IdentityProvider';

const log = new Logger('GenericAuthStateFilter', 'DEBUG');
// const insp = obj => inspect(obj, null, 2);

export const GenericAuthStateFilter = (props) => (
    <AuthContext.Consumer>
        { ( auth ) => {
            if (props.condition(auth))
                return props.children;
            if (props.onMismatch && !auth.loading) {
                log.debug(`AUTHFILTER_MISMATCH(name=${props.name}, condition=${props.condition})`);
                return props.onMismatch();
            }
            return (<></>);
        }}
    </AuthContext.Consumer>
);

GenericAuthStateFilter.propTypes = {
    name: PropTypes.string.isRequired,
    condition: PropTypes.func.isRequired,
    onMismatch: PropTypes.func,
}

export const AuthFilter = (props) => (
    <GenericAuthStateFilter {...props}  name="AuthFilter" condition={({ authState }) => authState === AuthState.SIGNEDIN}>
        {props.children}
    </GenericAuthStateFilter>
);

export const UnauthFilter = (props) => (
    <GenericAuthStateFilter {...props}  name="UnauthFilter" condition={({ authState }) => authState === AuthState.SIGNIN}>
        {props.children}
    </GenericAuthStateFilter>
);
    
export const EmailVerifiedFilter = (props) => (
    <GenericAuthStateFilter {...props}  name="EmailVerifiedFilter" condition={({ emailVerified }) => emailVerified}>
        {props.children}
    </GenericAuthStateFilter>
);
    
export const ProfileCompleteFilter = (props) => (
    <GenericAuthStateFilter {...props} name="ProfileCompleteFilter" condition={({ profileComplete }) => { console.log(`ProfileCompleteFilter(profileComplete=${profileComplete})`); return profileComplete}}>
        {props.children}
    </GenericAuthStateFilter>
);

export const CognitoIdpFilter = (props) => (
    <GenericAuthStateFilter {...props} name="CognitoIdpFilter" condition={({ identityProvider }) => identityProvider === IdentityProvider.COGNITO}>
        {props.children}
    </GenericAuthStateFilter>
);