import React from 'react';
import PropTypes from 'prop-types';

import { AuthFilter } from './AuthFilters';
import StandardLayout from '../StandardLayout';
import ProfileController from './ProfileController';

export default class extends React.Component {
    
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onMismatch: PropTypes.func.isRequired,
    };
    
    render() {
        return (
            <AuthFilter onMismatch={this.props.onMismatch}>
                <StandardLayout>
                    <ProfileController onSuccess={this.props.onSuccess} onCancel={this.props.onCancel}/>
                </StandardLayout>
            </AuthFilter>
        );
    }
}


