import React from 'react';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import ConfigContext from './ConfigContext';
import resolveSite from './SiteResolver';
import resolveConfig from './ConfigResolver';

const log = new Logger('ConfigProvider', 'DEBUG');

export default class ConfigProvider extends React.Component {
    
    static config;
    
    // Initialization has to happen in the constructor; descendents will likely want to use the provided config
    // to do setup in componentDidMount, but the descendents' componentDidMounts will run before this component's.
    // For demo, see https://codepen.io/shurikensystems/pen/xBrzJg.
    constructor(props) {
        super(props);
        
        const site = resolveSite();
        const config = resolveConfig(this.props.config, site);
        log.info(`CONFIG_LOADED(config=${inspect(config, null, 5)})`);
        this.state = { config };
    }
    
    render() {
        return (
            <ConfigContext.Provider value={this.state.config}>
                {this.props.children}
            </ConfigContext.Provider>
        );
    }
}