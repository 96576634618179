import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import StandardLayout from '../StandardLayout';

export default class extends React.Component {

    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
    }

    async componentDidMount() {
        await Auth.signOut();
        this.props.onSuccess();
    }

    render() {
        return (
            <StandardLayout>
                <p>Signing out...</p>
            </StandardLayout>
        );
    }
}