
import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

// import { Formik, Field, ErrorMessage } from 'formik';
import { withFormik } from 'formik';
import { Form, Button, ButtonToolbar, Popover, OverlayTrigger } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';

const log = new Logger('RespondToRollcallView', 'WARN');
const insp = obj => inspect(obj, null, 5);

const onSubmit = async ({ codeword }, { props, setSubmitting, resetForm }) => {
    log.info(`RESPOND_TO_ROLLCALL(codeword=${codeword})`);
    await props.respondToRollcall({ codeword });
    log.info(`RESPOND_TO_ROLLCALL_SUCCESS()`);
    setSubmitting(false);
    resetForm();
};

const validateCodeword = ({ codeword }) => /^[A-Za-z]{3}$/.test(codeword) ? {} : { codeword: 'Please enter three letters' };

function RespondToRollcallView(props) {

    const {
        values,
        // touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        touched,

        rollcall,
        onCancel,
        controllerErrors,
    } = props;

    log.debug(`RENDER_RTRVIEW(props=${insp(props)})`);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">About Privacy</Popover.Title>
            <Popover.Content>
                Your name, email address, and institution ID will be shared with the caller of this rollcall.
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            <FunctionCard
                heading="Respond to a Rollcall"
                error={controllerErrors}
                success={rollcall && `You have responded to ${rollcall.rollcallName}.`}
            >
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="enterCodeword">
                        <Form.Label>Enter codeword:</Form.Label>
                        <OverlayTrigger rootClose trigger="click" placement="bottom-end" overlay={popover}>
                            <Form.Control type="text" name="codeword" placeholder="ABC" onChange={handleChange} onBlur={handleBlur} value={values.codeword} isInvalid={touched.codeword && errors.codeword} />
                        </OverlayTrigger>
                        <Form.Control.Feedback type="invalid">{errors.codeword}</Form.Control.Feedback>
                    </Form.Group>
                    <ButtonToolbar>
                        <Button className="mr-2" type="submit" disabled={!isValid || isSubmitting}>Submit</Button>
                        {onCancel && (<Button variant="secondary" onClick={onCancel}>Cancel</Button>)}
                    </ButtonToolbar>
                </Form>
            </FunctionCard>
        </>
    );

}

RespondToRollcallView.propTypes = {
    loading: PropTypes.bool.isRequired,
    respondToRollcall: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    controllerErrors: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
    rollcall: PropTypes.shape({
        rollcallName: PropTypes.string.isRequired,
    }),
};

export default withFormik({
    mapPropsToValues: props => ({ codeword: '' }),
    handleSubmit: onSubmit,
    displayName: 'RespondToRollcallViewWithFormik',
    validate: validateCodeword,
})(RespondToRollcallView);