import React from 'react';
import { graphqlOperation, Logger } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import inspect from 'browser-util-inspect';

// import * as queries from '../graphql/queries';

import ResponsesView from './MyResponsesView';

const insp = obj => inspect(obj, null, 5);
const log = new Logger('ResponsesController', 'DEBUG');

export default class extends React.Component {
    
    getResponsesGqlOp = graphqlOperation(`
        query GetMyResponses {
            getMy {
                responses {
                    respondedUtc
                    rollcall {
                        codeword
                        rollcallName
                    }
                }
            }
        }
    `);

    render() {
        return (
            <Connect query={this.getResponsesGqlOp}>
                {({ data, errors, loading }) => {
                
                    // Payload shape looks like:
                    // { data: 
                    //   { getMy: 
                    //       { responses: 
                    //          [ { respondedUtc: '2019-07-05T03:14:00.785Z',
                    //              rollcall: { codeword: 'XDY', rollcallName: 'My New Rollcall' } },
                    //           { respondedUtc: '2019-07-05T18:06:52.008Z',
                    //              rollcall: { codeword: 'HZY', rollcallName: 'My New Rollcall' } } ] } },
                    //   errors: [],
                    //   loading: false,
                    //   mutation: [Function: mutation] }

                    log.debug(`RESPONSES_CONNECT(data=${insp(data)}, errors=${insp(errors)}, loading=${insp(loading)})`)
                    
                    errors && errors.forEach(error => log.error(`RESPONSES_CONNECT_ERROR(error=${insp(error)})`));
                    
                    return <ResponsesView errors={errors} loading={loading} responses={data && data.getMy ? data.getMy.responses : []}/>;
                }}
            </Connect>
        );
    }
}
