import React from 'react';
import PropTypes from 'prop-types';

import OpenRollcallOverviewView from './OpenRollcallOverviewView';
import ClosedRollcallOverviewView from './ClosedRollcallOverviewView';
import ResponsesToRollcallView from './ResponsesToRollcallView';

export default class RollcallView extends React.Component {

    static propTypes = {
        rollcall: PropTypes.object.isRequired,
        closeRollcall: PropTypes.func.isRequired,
        reopenRollcall: PropTypes.func.isRequired,
        frequency: PropTypes.number.isRequired,
        errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    };

    render() {

        const rollcall = this.props.rollcall;
        const { active } = rollcall;

        const OpenRollcall = (
            <OpenRollcallOverviewView
                rollcall={rollcall}
                closeRollcall={this.props.closeRollcall}
                error={this.props.errors}
            />);

        const ClosedRollcall =
            <ClosedRollcallOverviewView
                rollcall={rollcall}
                error={this.props.errors}
                lateResponsesEnabled={true}
                reopenRollcall={this.props.reopenRollcall}
            />

        return (
            <>
                {active ? OpenRollcall : ClosedRollcall}
                <br />
                <ResponsesToRollcallView
                    downloadEnabled={!active}
                    lateResponsesEnabled={!active}
                    rollcall={rollcall}
                    active={active}
                    frequency={this.props.frequency}
                />
            </>
        );
    }
};