import React from 'react';

import AuthHomePage from './AuthHomePage';
import UnauthHomePage from './UnauthHomePage';

export default class extends React.Component {
    
    render() {
        return (
            <>
                <AuthHomePage/>
                <UnauthHomePage/>
            </>
        );
    }
}