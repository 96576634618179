const mapping = {
    a: 'Alpha',
    b: 'Bravo',
    c: 'Charlie',
    d: 'Delta',
    e: 'Echo',
    f: 'Foxtrot',
    g: 'Golf',
    h: 'Hotel',
    i: 'India',
    j: 'Juliett',
    k: 'Kilo',
    l: 'Lima',
    m: 'Mike',
    n: 'November',
    o: 'Oscar',
    p: 'Papa',
    q: 'Quebec',
    r: 'Romeo',
    s: 'Sierra',
    t: 'Tango',
    u: 'Uniform',
    v: 'Victor',
    w: 'Whiskey',
    x: 'X-Ray',
    y: 'Yankee',
    z: 'Zulu',
}

export default class NatoAlphabet {
    
    static characterToWord(char) {
        
        return mapping[char.toLowerCase()] || '*';
    }
    
    static wordToCharacter(word) {
        
        let char = '*';
        let lcWord = word.toLowerCase();
        
        for (let key in mapping) {
            if (mapping[key].toLowerCase() === lcWord)
                char = key;
        }
        
        return char;
    }
    
    static stringToCharacters(str, sep = ' ') {
        
        let chars = str.split('').map(c => this.characterToWord(c));
        return chars.join(sep);
    }
}