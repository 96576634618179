import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';

import SmartPaymentButtons, { PayPalSDKWrapper } from 'react-smart-payment-buttons';

const log = new Logger('PayPalCheckout', 'DEBUG');

function PayPalCheckout({ clientId, onCapture, onFailure, onCancel, amount, currency }) {

    return (

        <PayPalSDKWrapper clientId={clientId}>
            <SmartPaymentButtons

                createOrder={function createOrder(data, actions) {
                    log.info(`CREATE_ORDER(data=${JSON.stringify(data)}, actions=${JSON.stringify(actions)})`);
                    // Set up the transaction
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: amount,
                            },
                            description: "I'm Here Today Signup",
                            soft_descriptor: "I'm Here Today",
                        }]
                    });
                }}

                onApprove={function onApprove(data, actions) {

                    log.info(`ON_APPROVE(data=${JSON.stringify(data)}, actions=${JSON.stringify(actions)})`);

                    // Data here looks like: { "orderID": "0FD08748X0433280A", "payerID": "J7WUSNT7AW8M8" }

                    return actions.order.capture().then(function (details) {
                        // See end of this file for example of what the details looks like
                        console.log(`PAYPAL_CAPTURE(details=${JSON.stringify(details)}`)
                        onCapture(details);
                    });
                }}

                onError={function onError(data, actions) {
                    console.error(`ON_ERROR(data=${JSON.stringify(data)}, actions=${JSON.stringify(actions)})`);
                    onFailure(data);
                }}

                // Don't want to accidentally shadow handler received in props
                onCancel={function onCancelHappened(data, actions) {
                    log.info(`ON_CANCEL(data=${JSON.stringify(data)}, actions=${JSON.stringify(actions)})`);
                    // Data on cancel looks like: { "orderID": "8R769047NX2286319" }
                    onCancel(data);
                }}
            />
        </PayPalSDKWrapper>
    );
};

PayPalCheckout.propTypes = {
    clientId: PropTypes.string.isRequired,
    onCapture: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
};

PayPalCheckout.defaultProps = {
    currency: 'USD',
};

export default PayPalCheckout;

/*
Order details in capture looks like:
{
  "create_time": "2019-08-26T02:38:26Z",
  "update_time": "2019-08-26T02:38:26Z",
  "id": "14Y01842EN433015E",
  "intent": "CAPTURE",
  "status": "COMPLETED",
  "payer": {
    "email_address": "paypal-buyer@shurikensystems.com",
    "payer_id": "J7WUSNT7AW8M8",
    "address": {
      "country_code": "US"
    },
    "name": {
      "given_name": "test",
      "surname": "buyer"
    }
  },
  "purchase_units": [
    {
      "reference_id": "default",
      "amount": {
        "value": "10.00",
        "currency_code": "USD"
      },
      "payee": {
        "email_address": "paypal-facilitator@shurikensystems.com",
        "merchant_id": "N26ECUL3SM59L"
      },
      "shipping": {
        "name": {
          "full_name": "test buyer"
        },
        "address": {
          "address_line_1": "1 Main St",
          "admin_area_2": "San Jose",
          "admin_area_1": "CA",
          "postal_code": "95131",
          "country_code": "US"
        }
      },
      "payments": {
        "captures": [
          {
            "status": "COMPLETED",
            "id": "8X337381TV106062E",
            "final_capture": true,
            "create_time": "2019-08-26T02:38:26Z",
            "update_time": "2019-08-26T02:38:26Z",
            "amount": {
              "value": "10.00",
              "currency_code": "USD"
            },
            "seller_protection": {
              "status": "ELIGIBLE",
              "dispute_categories": [
                "ITEM_NOT_RECEIVED",
                "UNAUTHORIZED_TRANSACTION"
              ]
            },
            "links": [
              {
                "href": "https://api.sandbox.paypal.com/v2/payments/captures/8X337381TV106062E",
                "rel": "self",
                "method": "GET",
                "title": "GET"
              },
              {
                "href": "https://api.sandbox.paypal.com/v2/payments/captures/8X337381TV106062E/refund",
                "rel": "refund",
                "method": "POST",
                "title": "POST"
              },
              {
                "href": "https://api.sandbox.paypal.com/v2/checkout/orders/14Y01842EN433015E",
                "rel": "up",
                "method": "GET",
                "title": "GET"
              }
            ]
          }
        ]
      }
    }
  ],
  "links": [
    {
      "href": "https://api.sandbox.paypal.com/v2/checkout/orders/14Y01842EN433015E",
      "rel": "self",
      "method": "GET",
      "title": "GET"
    }
  ]
}
*/