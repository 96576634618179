import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

const log = new Logger('CognitoUserAttributeMapper', 'DEBUG');
const insp = obj => inspect(obj, null, 2);

export const emptyAttributes = {
    email: '',
    emailVerified: false,
    givenName: '',
    familyName: '',
    institutionId: '',
};

export function mapFromCognitoAttributes(attributes) {
    
    log.debug(`MAP_FROM_COGNITO_ATTRIBUTES(attributes=${insp(attributes)})`);

    const mappedAttributes = {
        identities: attributes.identities,
        sub: attributes.sub,
        email: attributes.email,
        emailVerified: attributes.email_verified,
        givenName: attributes.given_name,
        familyName: attributes.family_name,
        institutionId: attributes['custom:institutionId'],
    };
    log.debug(`mappedAttributes: ${insp(mappedAttributes)}`);

    return mappedAttributes;
}

export function mapToCognitoAttributes(attributes) {

    const retval = {};

    if (attributes.sub) retval.sub = attributes.sub;
    if (attributes.email) retval.email = attributes.email;
    if (attributes.emailVerified) retval.email_verified = attributes.emailVerified;
    if (attributes.givenName) retval.given_name = attributes.givenName;
    if (attributes.familyName) retval.family_name = attributes.familyName;
    if (attributes.institutionId) retval['custom:institutionId'] = attributes.institutionId;

    return retval;
}
