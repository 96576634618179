import * as yup from 'yup';


export default yup.object()
    .shape({
        email: yup.string()
            .trim()
            .email('Please enter a valid email address')
            .required('Email is required'),
        givenName: yup.string()
            .trim()
            .max(256, 'Given name may be up to 256 characters long'),
        familyName: yup.string()
            .trim()
            .required('Family name is required')
            .min(1, 'Family name must be at least one character long')
            .max(256, 'Family name may be up to 256 characters long'),
        institutionId: yup.string()
            .trim()
            .required('Institution ID is required')
            .min(1, 'Institution ID must be at least one character long')
            .max(64, 'Institution ID may be up to 64 characters long'),
    })
    .noUnknown(false);
