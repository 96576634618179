import React from 'react';
import PropTypes from 'prop-types';

import FunctionCard from 'common/FunctionCard';
import RollcallCloserView from './RollcallCloserView';

import DateUtils from '../util/DateUtils';
import NatoAlphabet from '../util/NatoAlphabet';

export default class extends React.Component {

    static propTypes = {
        rollcall: PropTypes.object.isRequired,
        closeRollcall: PropTypes.func.isRequired,
        error: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
    };

    constructor(props) {
        super(props);

        this.state = {
            elapsed: '',
        };
    }

    componentDidMount() {

        this.secondTimer = setInterval(() => this.updateElapsedTime(), 1000);
    }

    componentWillUnmount() {

        clearInterval(this.secondTimer);
    }

    updateElapsedTime() {

        if (!this.props.rollcall) return;

        this.setState({ elapsed: DateUtils.elapsedTimeSinceTextForISOString(this.props.rollcall.openedUtc) });
    }

    render() {

        const rc = this.props.rollcall;

        return (
            <FunctionCard error={this.props.error}>
                <p style={{ paddingTop: '0em', marginTop: '0em', marginBottom: '0em', fontSize: '10em', textAlign: 'center' }}>{rc.codeword}</p>
                <p style={{ textAlign: 'center' }}>{NatoAlphabet.stringToCharacters(rc.codeword)}</p>
                <h2 style={{ textAlign: 'center' }}>{rc.rollcallName}</h2>
                <p style={{ textAlign: 'center' }}>Open for: {this.state.elapsed}</p>
                <RollcallCloserView rollcall={rc} closeRollcall={this.props.closeRollcall} />
            </FunctionCard>
        );
    }
}