import React from 'react';
import PropTypes from 'prop-types';

import { Logger, API } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import DateUtils from '../util/DateUtils';
import RollcallView from './RollcallView';

const insp = (obj) => inspect(obj, null, 5);
const log = new Logger('RollcallController', 'DEBUG');

const get = (p, o) =>
    p.reduce((xs, x) =>
        (xs && xs[x]) ? xs[x] : null, o)

// TODO: The timer teardown logic in here is kinda messy
export default class RollcallController extends React.Component {

    static propTypes = {
        rollcallId: PropTypes.string.isRequired,
    };

    getRollcallWithResponsesGql = `
        query rollcallWithResponses($rollcallId: String!) {
            getMy {
                rollcall(rollcallId: $rollcallId) {
                    rollcallId
                    rollcallName
                    codeword
                    rawScore
                    openedUtc
                    closedUtc
                    active
                    caller {
                        givenName
                        familyName
                        email
                        institutionId
                    }
                    responses {
                        responseId
                        respondedUtc
                        responder {
                            givenName
                            familyName
                            email
                            institutionId
                        }
                    }
                }
            }
        }
    `;

    closeRollcallGql = `
        mutation closeRollcall($rollcallId: ID!) {
            closeRollcall(rollcallId: $rollcallId) {
                rollcallId
                rawScore
                openedUtc
                closedUtc
                active
                caller {
                    givenName
                    familyName
                    email
                    institutionId
                }
                responses {
                    responseId
                    respondedUtc 
                    responder {
                        givenName
                        familyName
                        email
                        institutionId
                    }
                }
            }
        }
    `;

    reopenRollcallGql = `
        mutation reopenRollcall($rollcallId: ID!) {
            reopenRollcall(rollcallId: $rollcallId)
        }
    `;


    constructor(props) {
        super(props);

        this.state = { rollcall: null, loading: true, errors: null, frequency: 3 };
    }

    async fetchData(rollcallId) {

        /*
            Response looks like:

            { data: 
                { getMy: 
                    { rollcall: 
                        { rollcallId: '54c379e0-84c7-4149-b1dc-44f061b0247e',
                        rawScore: 98,
                        openedUtc: '2019-08-09T22:55:27.029Z',
                        closedUtc: null,
                        active: true,
                        caller: 
                            { givenName: 'Ima',
                            familyName: 'Responder',
                            email: 'responder20190628.1@ignorethis.org',
                            institutionId: '$context.source.callerInstitutionId' },
                        responses: [] } } } })
        */

        try {
            log.debug(`ROLLCALLCONTROLLER_FETCHDATA_ATTEMPT(rollcallId=${rollcallId})`);
            this.setState({ loading: true });
            const result = await API.graphql({ query: this.getRollcallWithResponsesGql, variables: { rollcallId } });
            log.debug(`ROLLCALLCONTROLLER_FETCHDATA_SUCCESS(rollcallId=${rollcallId}, result=${insp(result)})`);
            const rollcall = get(['data', 'getMy', 'rollcall'], result);
            this.setState({ rollcall, loading: false, errors: result.errors });
        }
        catch (e) {
            log.error(`ROLLCALLCONTROLLER_FETCHDATA_ERROR(error=${insp(e)})`);
            window.theError = e;
            this.setState({ errors: [e.message] });
        }
    }

    get elapsedRollcallTime() {
        
        if (!this.state.rollcall || !this.state.rollcall.openedUtc) return null;
        const elapsed = DateUtils.elapsedTimeInSecondsSinceISOString(this.state.rollcall.openedUtc);
        return elapsed;
    }

    registerTimedJob(frequency) {

        this.intervalId = setInterval(() => this.runTimedJob(this.props.rollcallId), frequency * 1000);
        this.setState({ frequency });
    }

    adjustTimerFrequency() {

        const frequency = this.chooseTimerFrequency(this.elapsedRollcallTime);
        
        if (frequency < 0) {
            this.clearTimer();
            return;
        }

        if (frequency === this.state.frequency) return;

        this.clearTimer();
        this.registerTimedJob(frequency);
    }

    runTimedJob(rollcallId) {

        console.log('Timed job');
        this.fetchData(rollcallId);
        this.invocations++;
        this.adjustTimerFrequency();
    }

    clearTimer() {

        this.intervalId && clearInterval(this.intervalId);
        this.setState({ frequency: -1 });
    }

    chooseTimerFrequency(elapsed) {
        
        switch (true) {
            case (elapsed < 3 * 60):
                return 3;
            case (elapsed < 5 * 60):
                return 10;
            case (elapsed < 10 * 60):
                return 30;
            case (elapsed < 30 * 60):
                return 60;
            case (elapsed < 60 * 60):
                return 5 * 60;
            default:
                return -1;
        }
    }

    closeRollcall = async (rollcallId) => {

        try {
            log.debug(`ROLLCALLCONTROLLER_CLOSEROLLCALL_ATTEMPT(rollcallId=${rollcallId})`);
            this.setState({ loading: true });
            const result = await API.graphql({ query: this.closeRollcallGql, variables: { rollcallId } });
            log.debug(`ROLLCALLCONTROLLER_CLOSEROLLCALL_SUCCESS(rollcallId=${rollcallId}, result=${insp(result)})`);
            this.setState({ loading: false, errors: result.errors });
            this.clearTimer();
            this.fetchData(rollcallId);
        }
        catch (e) {
            log.error(`ROLLCALLCONTROLLER_CLOSEROLLCALL_ERROR(error=${insp(e)})`);
            this.setState({ errors: [e.message] });
        }
    }

    reopenRollcall = async (rollcallId) => {

        try {
            log.debug(`ROLLCALL_CONTROLLER_REOPENROLLCALL_ATTEMPT(rollcallId=${rollcallId})`);
            this.setState({ loading: true });
            const result = await API.graphql({ query: this.reopenRollcallGql, variables: { rollcallId }});
            log.debug(`ROLLCALLCONTROLLER_REOPENROLLCALL_SUCCESS(rollcallId=${rollcallId}, result=${insp(result)})`);
            this.setState({ loading: false, errors: result.errors });
            await this.fetchData(rollcallId);
            this.registerTimedJob(this.state.frequency);
        }
        catch (e) {
            log.error(`ROLLCALLCONTROLLER_REOPENROLLCALL_ERROR(error=${insp(e)})`);

        }
    }

    async componentDidMount() {
        await this.fetchData(this.props.rollcallId); // Don't need to await
        if (this.state.rollcall && this.state.rollcall.active)
            this.registerTimedJob(this.state.frequency);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    render() {

        const rollcall = this.state.rollcall;
        const responses = get(['responses'], rollcall);

        // Don't want to have loading indicator here that changes layout--this page has timed updates
        return (
            <>
                {rollcall &&
                    <RollcallView
                        rollcall={rollcall}
                        closeRollcall={() => this.closeRollcall(rollcall.rollcallId)}
                        reopenRollcall={() => this.reopenRollcall(rollcall.rollcallId)}
                        responses={responses}
                        errors={this.state.errors}
                        frequency={this.state.frequency}
                    />}
            </>
        );
    }
};