import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Logger } from 'aws-amplify';

import { AuthFilter } from '../auth/AuthFilters';
import StandardLayout from '../StandardLayout';
import Rollcall from './RollcallController';

import { inspect } from 'util';

const log = new Logger('RollcallInProgressPage', 'DEBUG');
const insp = obj => inspect(obj, null, 5);

class RollcallPage extends React.Component {

    static propTypes = {
        onMismatch: PropTypes.func.isRequired,
    };

    render() {

        log.debug(`ROLLCALL_IN_PROGRESS_PAGE(props=${insp(this.props)})`);
        const rollcallId = this.props && this.props.match && this.props.match.params && this.props.match.params.rollcallId;
        log.debug(`ROLLCALL_IN_PROGRESS_PAGE(rollcalId=${rollcallId})`);

        return (
            <AuthFilter onMismatch={this.props.onMismatch}>
                <StandardLayout>
                    <Rollcall
                        rollcallId={rollcallId}
                    />
                </StandardLayout>
            </AuthFilter>
        );
    }
}

export default withRouter(RollcallPage);