import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AuthContext from './AuthContext';
import ConfirmEmailView from './ConfirmEmailView';

const log = new Logger('ConfirmEmailController', 'DEBUG');
const insp = obj => inspect(obj, null, 2);

class ConfirmEmailController extends React.Component {
    
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        confirmEmail: PropTypes.func.isRequired,
        resendCode: PropTypes.func.isRequired,
        error: PropTypes.string,
    };
    
    submitCode = async (code) => {
        
        try {
            log.debug(`USER_CONFIRM_CODE(code=${code})`);
            await this.props.confirmEmail(code);
            log.debug(`USER_CONFIRM_CODE_SUCCESSFUL()`);
            this.props.onSuccess();
        }
        catch(e) {
            log.error(`USER_CONFIRM_CODE_FAILED(error=${insp(e)})`);
        }
    }
    
    resendCode = async () => {
        
        try {
            log.debug(`RESEND_CODE()`);
            await this.props.resendCode();
            log.debug(`RESEND_CODE_SUCCESSFUL()`);
        }
        catch (e) {
            log.error(`RESEND_CODE_FAILED(error=${insp(e)})`);
        }
    }
    
    render() {
        return (
            <ConfirmEmailView
                submitCode={this.submitCode}
                resendCode={this.resendCode}
                error={this.props.error}
            />
        );
    }
}

export default React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
        { ({ confirmEmail, resendCode, error }) => (
            <ConfirmEmailController
                {...props}
                ref={ref}
                confirmEmail={confirmEmail}
                resendCode={resendCode}
                error={error}
            />
        )}
    </AuthContext.Consumer>
));