import React from 'react';

import StandardLayout from '../StandardLayout';
import Rollcalls from './RollcallsController';

export default class extends React.Component {

    render() {
        return (
            <StandardLayout>
                <Rollcalls/>
            </StandardLayout>
        );
    }
}