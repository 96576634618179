import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import AuthContext from './AuthContext';
import { AuthFilter } from './AuthFilters';
import ProfileView from './ProfileView';
import IdentityProvider from './IdentityProvider';

const log = new Logger('ProfileController', 'DEBUG');
const insp = obj => inspect(obj, null, 2);

class ProfileController extends React.Component {

    static propTypes = {
        updateAttributes: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            givenName: '',
            familyName: '',
            institutionId: '',
            error: null,
            loading: true,
        };
    }

    submit = async (attributes) => {
        try {
            log.debug(`PROFILE_SUBMIT(attributes=${insp(attributes)})`);
            await this.props.updateAttributes(attributes);
            log.debug(`PROFILE_SUBMIT_SUCCESSFUL()`);
            this.props.onSuccess && this.props.onSuccess();
        }
        catch (e) {
            log.error(`PROFILE_SUBMIT_FAILED(${insp(e)})`);
        }
    }

    render() {
        log.debug(`RENDERING_PROFILE_CONTROLLER(props=${insp(this.props)})`);
        return (
            <AuthFilter>
                <ProfileView
                    email={this.props.attributes.email}
                    givenName={this.props.attributes.givenName}
                    familyName={this.props.attributes.familyName}
                    institutionId={this.props.attributes.institutionId}
                    onSubmit={this.submit}
                    onCancel={this.props.profileComplete ? this.props.onCancel : null}
                    loading={this.props.loading}
                    controllerErrors={this.props.error}
                    allowEmailChange={this.props.identityProvider === IdentityProvider.COGNITO}
                />
            </AuthFilter >
        );
    }
}

export default React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
        {auth => (
            <ProfileController
                {...props}
                ref={ref}
                {...auth}
            />
        )}
    </AuthContext.Consumer>
));