import React from 'react';
import { Logger } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';

import inspect from 'browser-util-inspect';

import RollcallsView from './RollcallsView';

const insp = (obj) => inspect(obj, null, 5);
const log = new Logger('RollcallInProgressController', 'DEBUG');

export default class  extends React.Component {

    rollcallsGql = `
        query rollcalls {
            getMy {
                rollcalls {
                    rollcallId
                    codeword
                    rollcallName
                    rawScore
                    openedUtc
                    closedUtc
                    active
                    caller {
                        givenName
                        familyName
                        email
                        institutionId
                    }
                }
            }
        }
    `;

    render() {
        return (
            <Connect 
                query={{
                    query: this.rollcallsGql,
                    variables: {},
                }}
            >
                {({ data, errors, loading }) => {

                    log.debug(`ROLLCALLS_QUERY(data=${insp(data)}, errors=${insp(errors)}, loading=${insp(loading)})`);

                    errors && errors.forEach(error => log.error(`ROLLCALLS_QUERY_ERRORS(error=${insp(error)})`));

                    const dataPresent = !!(data && data.getMy);
                    const rollcalls = dataPresent ? data.getMy.rollcalls : [];

                    return (
                        <RollcallsView 
                            rollcalls={rollcalls}
                            loading={loading}
                            errors={errors}
                        />
                    );
                }}
            </Connect>
        );
    }
};