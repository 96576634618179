import React from 'react';
import PropTypes from 'prop-types';
import { Table, Col, Alert } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';

import DateUtils from '../util/DateUtils';

export default class RollcallsView extends React.Component {

    static propTypes = {
        rollcalls: PropTypes.array.isRequired,
    }

    render() {
        return (
            <FunctionCard heading='My Rollcalls'>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Codeword</th>
                            <th>Name</th>
                            <th>Raw Score</th>
                            <th>Opened</th>
                            <th>Closed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rollcalls.map(
                            ({ rollcallName, codeword, openedUtc, closedUtc, rollcallId, rawScore }) => (
                                <tr key={rollcallId}>
                                    <td>
                                        <a key={rollcallId} href={`/rollcall/${rollcallId}`}>
                                            {codeword}
                                        </a>
                                    </td>
                                    <td>{rollcallName}</td>
                                    <td>{rawScore}</td>
                                    <td>{DateUtils.formatISOString(openedUtc)}</td>
                                    <td>{DateUtils.formatISOString(closedUtc)}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
                {this.props.rollcalls.length < 1 &&
                    <Col md={{ span: 4, offset: 4 }}>
                        <Alert variant="success">
                            <h3 style={{ textAlign: 'center', margin: "3rem" }}>
                                None yet!
                            </h3>
                        </Alert>
                    </Col>
                }
            </FunctionCard>
        )
    }
}