import React from 'react';
import PropTypes from 'prop-types';

import { Logger } from 'aws-amplify';
import inspect from 'browser-util-inspect';

import { Formik, Form as FormikForm, Field } from 'formik';
import * as yup from 'yup';
import { Form as BSForm, Button, ButtonToolbar } from 'react-bootstrap';

import FunctionCard from 'common/FunctionCard';

const insp = obj => inspect(obj, null, 5);
const log = new Logger('CreateRollcallView', 'DEBUG');

export default class CreateRollcallView extends React.Component {

    static propTypes = {
        createRollcall: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        loading: PropTypes.bool,
        controllerErrors: PropTypes
            .oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
            ]),
    };

    constructor(props) {

        super(props);

        this.state = {
            rollcallName: '',
            rawScore: '',
            institutionId: '',
        };
    }

    validationSchema = yup.object()
        .shape({
            rollcallName: yup
                .string()
                .min(1, 'Please enter a name for your rollcall')
                .max(256, 'Please enter no more than 256 characters')
                .required('Please enter a name for your rollcall'),
            rawScore: yup
                .number()
                .typeError('Please enter a number')
                .min(0, 'Please enter a positive number')
                .notRequired(),
        });

    submit = async ({ rollcallName, rawScore }, setSubmitting) => {

        try {
            const result = await this.props.createRollcall({ rollcallName, rawScore });
            log.info(`CREATE_ROLLCALL_SUCCESS(result=${result})`);
        }
        catch (e) {
            // This shouldn't happen
            log.error(insp(e));
        }

        setSubmitting(false);
    };

    render() {

        return (
            <FunctionCard heading="Create Rollcall" error={this.props.controllerErrors} >
                <Formik
                    initialValues={{
                        rollcallName: '',
                        rawScore: '',
                    }}
                    onSubmit={(values, { setSubmitting }) => this.submit(values, setSubmitting)}
                    validationSchema={this.validationSchema}
                >
                    {({ isSubmitting, values, touched, errors, isValid }) => (
                        <BSForm as={FormikForm}>
                            <BSForm.Group controlId="rollcallName">
                                <BSForm.Label>Rollcall name</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="rollcallName"
                                    minLength="1"
                                    maxLength="256"
                                    isInvalid={errors.rollcallName}
                                />
                                <BSForm.Control.Feedback type="invalid" name="rollcallName">
                                    {errors.rollcallName}
                                </BSForm.Control.Feedback>
                            </BSForm.Group>
                            <BSForm.Group controlId="rawScore">
                                <BSForm.Label>Raw score</BSForm.Label>
                                <BSForm.Control
                                    as={Field}
                                    type="text"
                                    name="rawScore"
                                    isInvalid={errors.rawScore}
                                />
                                <BSForm.Control.Feedback type="invalid" name="rawScore">{errors.rawScore}</BSForm.Control.Feedback>
                            </BSForm.Group>
                            <ButtonToolbar>
                                <Button variant="primary" className="mr-2" type="submit" disabled={isSubmitting || !isValid}>Start Rollcall</Button>
                                {this.props.onCancel && <Button variant="secondary" type="button" onClick={this.props.onCancel}>Cancel</Button>}
                            </ButtonToolbar>
                        </BSForm>
                    )}
                </Formik>
            </FunctionCard>
        );
    }
}