import React, { Component } from 'react';
import './App.css';

import config from './Config.json';
import ConfigProvider from './config/ConfigProvider';
import AmplifyProvider from './amplify/AmplifyProvider';
import AuthProvider from './auth/AuthProvider';
import Routes from './Routes.js';

class App extends Component {

    render() {
        return (
            <div className="App">
                <ConfigProvider config={config}>
                    <AmplifyProvider>
                        <AuthProvider>
                            <Routes/>
                        </AuthProvider>
                    </AmplifyProvider>
                </ConfigProvider>
            </div>
        );
    }
}

export default App;


