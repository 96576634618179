import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';

import ErrorToast from './ErrorToast';
import SuccessToast from './SuccessToast';

export default function FunctionCard(props) {

    const {
        heading,
        headingNote,
        error,          // This is for errors coming from the controller
        success,
        children,
    } = props;

    if (!(heading || headingNote || error || success || children))
        return <></>;

    const successes = typeof success === 'string' ? [success] : !success ? [] : success;
    const errors = typeof error === 'string' ? [error] : !error ? [] : error;

    return (
        <Card style={{ padding: "1.5rem" }}>
            <Card.Title>
                <Row className="justify-content-between align-self-center">
                    <Col >{heading}</Col>
                    <Col className="text-right align-self-center" style={{ fontSize: "65%" }}>{headingNote}</Col>
                </Row>
            </Card.Title>
            <Card.Body>
                {successes.map((s, i) => (<SuccessToast key={i} header="Success!" message={s} />))}
                {errors.map((s, i) => (<ErrorToast key={i} header="Oops!" message={s} />))}
                {children}
            </Card.Body>
        </Card >
    );
};

FunctionCard.propTypes = {
    heading: PropTypes.string,
    headingNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    success: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};